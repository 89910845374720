import { Button, TableCell, TableRow } from "@mui/material";
import React, { useCallback, useState } from "react";
import api from "../../../../api/api";

const InteractiveTableRow = ({ user }) => {
  console.log(user.disabled, "state in row");
  const [userDisabled, setUserDisabled] = useState(user.disabled);

  const handleState = useCallback(
    async (ev) => {
      ev.preventDefault();
      api.admin.updateUser(!userDisabled, user.user_id).then((res) => {
        setUserDisabled((prev) => !prev);
        console.log(res);
      });
    },
    [user.user_id, userDisabled]
  );
  return (
    <TableRow>
      <TableCell>{user.user_id}</TableCell>
      <TableCell>{user.name}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>
        <Button onClick={handleState} variant="contained">
          {userDisabled ? "enable" : "disable"}
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default InteractiveTableRow;
