import { Stack, Typography } from '@mui/material'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import UserSearch from './UserSearch'
import ViewUser from './ViewUser'

const Users = () => {
  return (
    <Stack spacing={2}>
      <Typography variant="h5">
        Users
      </Typography>
      <Routes>
        <Route index element={<UserSearch/>}/>
        {/* <Route path="/:id" element={<ViewUser/>}/> */}
      </Routes>
    </Stack>
  )
}

export default Users
