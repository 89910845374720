import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackwardIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";

function Pagination({
  currentPage,
  totalPages,
  nextCallback,
  previousCallback,
}) {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      width="100%"
    >
      {currentPage > 0 && (
        <Button
          variant="text"
          startIcon={<ArrowBackwardIcon />}
          onClick={previousCallback}
        >
          Previous
        </Button>
      )}
      <Typography variant="h3" color="grey.400">
        {currentPage + 1}/{Math.max(totalPages, 1)}
      </Typography>
      {currentPage + 1 < totalPages && (
        <Button
          variant="text"
          endIcon={<ArrowForwardIcon />}
          onClick={nextCallback}
        >
          Next
        </Button>
      )}
    </Stack>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  nextCallback: PropTypes.func.isRequired,
  previousCallback: PropTypes.func.isRequired,
};

export default Pagination;
