import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as HeroImg } from "../../../assets/Hero.svg";

import { ReactComponent as GmailLogo } from "../../../assets/gmail.svg";
import { ReactComponent as ChromeLogo } from "../../../assets/chrome.svg";
import { ReactComponent as TabsLogo } from "../../../assets/tabs.svg";
import { ReactComponent as BookmarksLogo } from "../../../assets/bookmarks.svg";
import { ReactComponent as LinkedInLogo } from "../../../assets/linkedIn.svg";
import Phase1 from "./Phase1";
import Phase2 from "./Phase2";
import Phase3 from "./Phase3";

const StyledStack = styled(Stack)`
  background-color: #fff9f5;
  align-items: center;
  justify-content: flex-start;
`;
const Hero = () => {
  const [phase, setPhase] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <StyledStack
      spacing={2}
      padding={"3rem"}
      flexGrow={1}
      sx={{ minHeight: "75vh", textAlign: "center" }}
    >
      <HeroImg />
      <Stack
        spacing={2}
        direction={isMobile ? "column" : "row"}
        alignItems={"center"}
      >
        <Typography variant={"h6"}>
          Say goodbye 👋 to copy/pasting URLs and social links. Plays nicely
          with:
        </Typography>
        <Stack direction="row" spacing={2}>
          <ChromeLogo />
          <GmailLogo />
          <LinkedInLogo />
          <TabsLogo />
          <BookmarksLogo />
        </Stack>
      </Stack>
      {phase === 0 ? (
        <Phase1 setPhase={setPhase} />
      ) : phase === 1 ? (
        <Phase2 setPhase={setPhase} />
      ) : phase === 2 ? (
        <Phase3 setPhase={setPhase} />
      ) : (
        <></>
      )}
    </StyledStack>
  );
};

export default Hero;
