import { createTheme, ThemeProvider } from "@mui/material";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

function AppTheme({ children }) {
  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: "Montserrat, Roboto, Arial, sans-serif",
          h1: {
            fontSize: "3.1rem",
            fontWeight: 700,
          },
          h2: {
            fontSize: "2rem",
          },
          h3: {
            fontSize: "1.5rem",
            fontWeight: 500,
          },
          h4: {
            fontSize: "1.25rem",
            fontWeight: 400,
          },
          h5: {
            fontSize: "1rem",
          },
          h6: {
            fontSize: "0.85rem",
          },
        },
        palette: {
          primary: {
            main: "#42DEB4",
            dark: "#57AC95",
            light: "#10A35C",
          },
          primaryLight: {
            main: "#E8F6EF",
            dark: "#cfe3d9",
            light: "#f7fffb",
            contrastText: "#10A35C",
          },
          white: {
            main: "#fff",
            contrastText: "#10A35C",
          },
          black: {
            main: "#000",
            contrastText: "#fff",
          },
          grey: {
            main: "#A9A9A9",
            dark: "#656565",
            light: "#C3C3C3",
            contrastText: "#00",
          },
          pink: {
            main: "#EF5CA3",
          },
          orange: {
            main: "#EE9D4C",
          },
          text: {
            white: "#fff",
          },
        },
        components: {
          MuiTypography: {
            styleOverrides: {
              root: {
                overflowWrap: "break-word",
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: "none",
                fontWeight: 400,
                flexShrink: 0,
              },
            },
            defaultProps: {
              variant: "contained",
              disableElevation: true,
            },
          },
          MuiCard: {
            styleOverrides: {
              root: {
                borderRadius: "6px",
                padding: "1rem",
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                borderRadius: "6px",
                padding: "1rem",
              },
            },
            defaultProps: {
              elevation: 3,
            },
          },
          MuiLink: {
            styleOverrides: {
              root: {
                cursor: "pointer",
                // textDecorationLine: "none",
                textDecorationColor: "#2AA5FC",
                fontWeight: 400,
              },
            },
          },

          MuiTextField: {
            defaultProps: {
              fullWidth: true,
            },
          },

          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                borderRadius: "6px",
              },
            },
          },

          MuiDialogTitle: {
            defaultProps: {
              variant: "h4",
            },
          },
        },
      }),
    []
  );
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

AppTheme.propTypes = {
  children: PropTypes.any,
};

export default AppTheme;
