import { Link, List, ListItemText, Stack, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const StyledSpan = styled("span")`
  font-weight: 700;
`;

const StyledOL = styled("ol")`
  & > li {
    padding: 0.5rem;
  }
`;
const StyledLink = styled(Link)`
  background-color: #ffff00;
  color: #000000;
  cursor: pointer;
`;
const TermsOfService = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms of service</title>
      </Helmet>
      <Stack
        alignItem="center"
        spacing={2}
        sx={{ maxWidth: "100%", padding: "2.5rem" }}
      >
        <Typography variant="h2" sx={{ textAlign: "center" }}>
          TERMS OF SERVICE
        </Typography>

        <Typography variant="p" sx={{ fontWeight: "700" }}>
          Last Updated: November 23, 2022
        </Typography>

        <Typography variant="p">
          These Terms of Service are entered into by and between AC Advisory,
          LLC, a Massachusetts Limited Liability Company D.B.A. Qlink (“AC
          Advisory”, “Qlink”, “Company”, “Us”), and its Users and visitors to
          the service “Services”, and together with the{" "}
          <StyledLink href="/privacy-policy">Privacy Policy</StyledLink>, govern
          those persons’ access to the Services, as well as any content or
          functionality of the Services, whether as a visitor or a User. The
          Company provides the Site to you subject to this Agreement. By
          accessing and/or using the Site, you (the "Customer") agree to be
          bound by these Terms and Conditions of Use. If you do not accept these
          Terms and Conditions of Use, you are not authorized to use and/or
          access the Site.
        </Typography>

        <Typography variant="p" sx={{ fontWeight: 700 }}>
          BY ACCESSING OR USING THE SERVICE, YOU AGREE TO COMPLY WITH AND BE
          BOUND BY THE TERMS OF SERVICE SET FORTH IN HEREIN (THE "TERMS"),
          WHETHER OR NOT YOU BECOME A REGISTERED USER OF THE SERVICE. FAILURE TO
          USE THE SERVICE IN ACCORDANCE WITH THESE TERMS AND{" "}
          <StyledLink href="/privacy-policy">Privacy Policy</StyledLink> MAY
          SUBJECT YOU TO CIVIL AND CRIMINAL PENALTIES.
        </Typography>

        <StyledOL>
          <li>
            <StyledSpan>
              Your Personal Data Rights & General Data Protection Regulation
              (GDPR).
            </StyledSpan>
            How we use your data and your data privacy rights are covered under
            our <StyledLink href="/privacy-policy">Privacy Policy</StyledLink>{" "}
            <Link href="https://getqlink.io/privacy-policy">
              https://getqlink.io/privacy-policy
            </Link>{" "}
            and are hereby integrated into these Terms of Service. If you have
            questions concerning your data rights and our obligations under the
            GDPR please consult our{" "}
            <StyledLink href="/privacy-policy">Privacy Policy</StyledLink>. Our{" "}
            <StyledLink href="/privacy-policy">Privacy Policy</StyledLink>{" "}
            covers a variety information about your data rights including but
            not limited to our obligations and rights as a processor,
            obligations and rights of the controller, subject matter of data
            processing, duration of data processing, nature and purpose of data
            processing, type of personal data collected, categories of data
            subjects collected, and special categories of personal data
            collected
          </li>

          <li>
            <StyledSpan>Changes</StyledSpan>
            Company reserves the right to change or modify these Terms at any
            time and in our sole discretion. If we make changes to these Terms,
            we will provide notice of such changes, such as by sending an email
            notification, providing notice through the Services or updating the
            “Last Updated” date at the beginning of these Terms. By continuing
            to access or use the Services or order, receive or use Services, you
            confirm your acceptance of the revised Terms and all of the terms
            incorporated therein by reference. We encourage you to review the
            Terms frequently to ensure that you understand the Terms of Service
            that apply when you access or use the Services or order, receive or
            use the Services.
          </li>

          <li>
            <StyledSpan>Service</StyledSpan>
            We provide a plug-in-based service which connects through Linkedin.
            By Installing our plugin “Qlink” and agreeing to the terms herein,
            users are allowed to create hyperlinks linking to 1st degree
            Linkedin connections. The software will remember links that each
            user creates and use an algorithm to suggest links in future emails.
            <StyledOL>
              <li>
                You should exercise caution and perform your own screening
                before connecting with anyone through our Services. By using
                this service, you expressly waive any claim against us relating
                to our services, and understand that we are not responsible for
                any services you ultimately receive.
              </li>
              <li>
                Any relationship entered into between you and any third party
                that arose from information received or accessed through Company
                is solely between you and the third party. Company is not a
                party to your agreements and you assume sole liability for any
                such agreements. We are not responsible for any transaction that
                may occur between you or your company and a Company client.
              </li>
            </StyledOL>
          </li>
          <li>
            <StyledSpan>Conditions Of Admission.</StyledSpan>
            You must agree to these Terms of Service in order to use our
            Services. They govern your use of our Services. If you do not accept
            the <StyledLink href="/privacy-policy">
              Privacy Policy
            </StyledLink>{" "}
            and these Terms of Service, you are not entitled to access our
            Services. By using our Services, you represent and warrant that you
            have the legal capacity to enter a contract in the jurisdiction
            where you reside.
          </li>
          <li>
            <StyledSpan>Conditions Of Access.</StyledSpan>
            <StyledOL style={{ listStyleType: "lower-alpha" }}>
              <li>
                You shall login via your created credentials when signing up to
                our Services.
              </li>
              <li>
                You may be required to verify that each party is consenting to
                services via email.
              </li>
              <li>
                Each Member shall keep their profile confidential and undertakes
                not to notify or disclose them to third parties or other Members
                to prevent fraud or phishing.
              </li>
              <li>
                Each Member shall take care not to disclose strictly personal
                information.
              </li>
              <li>
                We will take any necessary measures to halt fraudulent behavior,
                including to prevent the prohibited sharing of profiles.
              </li>
              <li>
                Each User undertakes not to carry out any action likely to
                hinder the operation of the Services and undertakes not to
                disseminate or arrange for the dissemination of viruses, spam,
                logic bombs, etc.
              </li>
            </StyledOL>
          </li>
          <li>
            <StyledSpan> Fee</StyledSpan>
            Company may charge for various levels of service. When you sign up
            for a service you agree to allow us to bill your credit card (or
            other payment method) for all due charges. We will re-bill for any
            failed billing or past-due amounts. Fees will not be prorated or
            refunded. We will bill you a full period’s fee regardless of whether
            you cancel within that period. We reserve the right to revise
            pricing at any time; however, we will provide you with at least 30
            days’ advance notice before revised terms become applicable to you
            (or such longer period of notice as may be required by law). We also
            reserve the right to offer different levels of service in
            alternative price plans and to impose different restrictions or
            levels of service in such alternative plans.
          </li>
          <li>
            <StyledSpan>Content Rights and Licenses.</StyledSpan>
            The Services, and all Content other than User Content and all
            software available on the Services or used to create and operate the
            Services, is and remains the property of Company, and is protected
            under the Copyright Act of 1976, as amended, and other intellectual
            property laws of the United States and any foreign jurisdiction
            where the Services are accessed, and all rights to the Services,
            such Content, and such software are expressly reserved.
            <StyledOL style={{ listStyleType: "lower-alpha" }}>
              <li>
                All trademarks and service marks, whether registered or
                unregistered, as well as product names and company names or
                logos, displayed or mentioned on the Services are the property
                of their respective owners.
              </li>
              <li>
                You must not use such marks without the prior written permission
                of the owner of the marks. Reference to any products, services,
                processes, or other information, by trade name, trademark,
                manufacturer, supplier, or otherwise does not constitute or
                imply endorsement, sponsorship, or recommendation thereof by
                Company.
              </li>
            </StyledOL>
          </li>
          <li>
            <StyledSpan>Limited License to Copy Content</StyledSpan>
            Company grants to Users a limited, revocable, non-exclusive,
            non-sublicensable license to access the Services and to view, copy,
            and print the portions of the Content available to Users on the
            Services.
            <StyledOL style={{ listStyleType: "lower-alpha" }}>
              <li>
                Such license is subject to these Terms of Service, and
                specifically conditioned upon the following:
              </li>
              <li>
                Users may only view, copy, and print such portions of the
                Content for their own personal use;
              </li>
              <li>
                Users may not modify or otherwise make derivative works of the
                Services or Content, or reproduce, distribute, or display the
                Services or any Content (except for page caching) except as
                expressly permitted in these Terms of Service;
              </li>
              <li>
                Users may not remove or modify any copyright, trademark, or
                other proprietary notices that have been placed in the Content;
              </li>
              <li>
                Users may not use the Services or Content other than for their
                intended purposes.
              </li>
              <li>
                Any such unauthorized use may also violate applicable laws,
                including without limitation copyright and trademark laws.
                Unless explicitly stated herein, nothing in these Terms of
                Service may be construed as conferring any license to
                intellectual property rights, whether by estoppels, implication,
                or otherwise.{" "}
              </li>
              <li>
                Company may revoke this license at any time for any reason or no
                reason, and shall not be liable to any User for any purported
                interference with business or contractual relations, in tort or
                otherwise.
              </li>
            </StyledOL>
          </li>
          <li>
            <StyledSpan>Intellectual Property.</StyledSpan>
            Users may not engage in any activity on or through the Services,
            including transmitting or using User Content that infringes or
            otherwise makes unauthorized use of another party’s copyright,
            trademark, or other intellectual property or proprietary rights.
            <StyledOL style={{ listStyleType: "lower-alpha" }}>
              <li>
                We will respond to legitimate requests made pursuant to the
                Online Copyright Infringement Liability Limitation Act of the
                Digital Millennium Copyright Act (17 U.S.C. § 512) ("DMCA") and
                trademark law, and we retain the right to remove Content from
                the Services that Company believes in good faith infringes on
                any third party’s intellectual property rights upon notice from
                the owner of such intellectual property, or their agent.{" "}
              </li>
            </StyledOL>
          </li>
          <StyledSpan>DISPUTE PROCESS</StyledSpan>
          <li>
            <StyledSpan>Assumption of Risk. </StyledSpan>
            You are solely responsible for ensuring that your use of the
            Services complies with applicable law and does not violate the
            rights of any third party, including, without limitation,
            intellectual property rights. You assume all liability for any
            claims, suits or grievances filed against you, including, but not
            limited to, all damages related to your use of the Services.
          </li>
          <li>
            <StyledSpan>Process</StyledSpan>
            <StyledOL>
              <li>
                <StyledSpan>Contact Us.</StyledSpan>
                You agree to contact us with your complaint prior to filing for
                any arbitration.
              </li>
              <li>
                <StyledSpan>File Complaint </StyledSpan>
                You and Company agree that any dispute must be commenced or
                filed by you or Company within one (1) year of the date the
                dispute arose, otherwise the underlying claim is permanently
                barred (which means that you and Company will no longer have the
                right to assert such claim regarding the dispute).
              </li>
              <li>
                <StyledSpan>Arbitration. </StyledSpan>
                You and Company agree that (a) any arbitration will occur in the
                State of Massachusetts, (b) arbitration will be conducted
                confidentially by a single arbitrator in accordance with the
                rules of the Judicial Arbitration and Mediation Services, which
                are hereby incorporated by reference, and (c) that the state or
                federal courts of the State of Massachusetts and the United
                States, respectively, have exclusive jurisdiction over any
                appeals and the enforcement of an arbitration award. You may
                also litigate a Dispute in the small claims court located in the
                county of your billing address if the Dispute meets the
                requirements to be heard in small claims court.
              </li>
            </StyledOL>
          </li>
          <li>
            <StyledSpan>
              California Civil Code Section 1789.3 Compliance
            </StyledSpan>
            Pursuant to California Civil Code Section 1789.3, any questions
            about pricing, complaints, or inquiries about Company must be
            addressed to our agent for notice and sent via certified mail to
            that agent. For our agent’s most current contact information, please
            send a request to [Email]
            <StyledOL style={{ listStyleType: "lower-alpha" }}>
              <li>
                California users are entitled to the following specific consumer
                rights notice: The Complaint Assistance Unit of the Division of
                Consumer Services of the California Department of Consumer
                Affairs may be contacted in writing or at (800) 952-5210.
              </li>
            </StyledOL>
          </li>
          <li>
            <StyledSpan>Communications.</StyledSpan>
            You consent to receive electronic communications, and you agree that
            all agreements, notices, disclosures, and other communications we
            provide to you electronically, via email and on the Service, satisfy
            any legal requirement that such communication be in writing.
            <StyledOL style={{ listStyleType: "lower-alpha" }}>
              You hereby agree to the use of electronic signatures, contracts,
              orders, and other records, and to electronic delivery of notices,
              policies, and records of transactions initiated or completed by us
              or via the Service.
            </StyledOL>
          </li>

          <li>
            <StyledSpan>Third Party Sites.</StyledSpan>
            he Service may contain links to third party websites, including
            links to the websites of carriers (“Linked Sites”). We are not
            liable for nor in control of third party Linked sites, and we are
            not responsible for the contents of any Linked Site, including any
            link contained in a Linked Site, or any changes or updates to a
            Linked Site. You should contact the site administrator or webmaster
            for those Linked Sites if you have any concerns regarding such links
            or the content located there.
          </li>
          <li>
            <StyledSpan>Disclaimer of Warranties </StyledSpan>
            COMPANY DOES NOT WARRANT THAT ANY CONTENT ON THE SERVICE, DOCUMENT
            OR FEATURE OF THE SERVICE WILL BE ERROR-FREE OR UNINTERRUPTED, OR
            THAT ANY DEFECTS WILL BE CORRECTED OR THAT YOUR USE OF THE SERVICE
            WILL PROVIDE SPECIFIC RESULTS. THE SERVICE AND ITS CONTENT ARE
            DELIVERED ON AN “AS-IS” AND “AS-AVAILABLE” BASIS. ALL INFORMATION
            PROVIDED ON THE SERVICE IS SUBJECT TO CHANGE WITHOUT NOTICE. COMPANY
            DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY
            WARRANTIES OF ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY AND
            FITNESS FOR A PARTICULAR PURPOSE. COMPANY DISCLAIMS ALL WARRANTIES,
            EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF ACCURACY,
            NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR
            PURPOSE. COMPANY DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS,
            OMISSIONS AND CONDUCT OF ANY THIRD PARTIES IN CONNECTION WITH OR
            RELATED TO YOUR USE OF THE SERVICE. COMPANY DOES NOT VERIFY FOR
            ACCURACY ANY INFORMATION PROVIDED TO US FROM THIRD-PARTIES. YOU
            ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE SERVICE AND ANY
            LINKED SERVICES. YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO
            YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM USED IN CONNECTION
            WITH THE SERVICE) OR LOSS OF DATA THAT RESULTS FROM THE USE OF THE
            SERVICE OR THE DOWNLOAD OR USE OF SUCH MATERIALS OR CONTENT. SOME
            JURISDICTIONS MAY PROHIBIT A DISCLAIMER OF WARRANTIES AND THEREFORE
            THIS DISCLAIMER WOULD NOT BE APPLICABLE IN THOSE JURISDICTIONS. YOU
            MAY ALSO HAVE OTHER RIGHTS THAT VARY FROM JURISDICTION TO
            JURISDICTION.YOUR SOLE REMEDY AGAINST COMPANY FOR DISSATISFACTION,
            IS TO STOP USING THE SERVICE OR ANY SUCH CONTENT.
          </li>
          <li>
            <StyledSpan>Limitation of Liability.</StyledSpan>
            To the fullest extent permitted by applicable law, in no event shall
            the Company parties be liable for any indirect, special, incidental,
            or consequential damages of any kind (including, but not limited to,
            loss of revenue, income or profits, loss of use or data, loss or
            diminution in value of assets, or damages for business interruption)
            arising out of or in any way related to the access to or use of the
            Services or content (including, but not limited to, user content,
            third party content and links to third party Services), or the
            order, receipt or use of any product, or otherwise related to these
            terms (including, but not limited to, any damages caused by or
            resulting from reliance on any information obtained from any Company
            party, or from events beyond the Company parties’ reasonable
            control, such as Service interruptions, deletions of files or
            emails, errors or omissions, defects, bugs, viruses, trojan horses,
            delays in operation or transmission or any failure of performance,
            whether or not resulting from acts of god, communications failure,
            theft, destruction or unauthorized access to the Company parties’
            records, programs or systems), regardless of the form of action,
            whether based in contract, tort (including, but not limited to,
            simple negligence, whether active, passive or imputed) or any other
            legal or equitable theory (even if the party has been advised of the
            possibility of such damages and regardless of whether such damages
            were foreseeable).
          </li>
          <li>
            <StyledSpan>Indemnification.</StyledSpan>
            To the fullest extent permitted by applicable law, you agree to
            indemnify, defend and hold harmless Company, and our respective
            past, present and future employees, officers, directors,
            contractors, consultants, equity holders, suppliers, vendors,
            service providers, parent companies, subsidiaries, affiliates,
            agents, representatives, predecessors, successors and assigns
            (individually and collectively, the “Company Parties”), from and
            against all actual or alleged Company Party or third party claims,
            damages, awards, judgments, losses, liabilities, obligations,
            penalties, interest, fees, expenses and costs of every kind and
            nature whatsoever, whether known or unknown, foreseen or unforeseen,
            matured or unmatured, or suspected or unsuspected, in law or equity,
            whether in tort, contract or otherwise (collectively, “Claims”),
            including, but not limited to, damages to property or personal
            injury, that are caused by, arise out of or are related to:
            <StyledOL style={{ listStyleType: "lower-alpha" }}>
              <li>
                Any use or misuse of the Content or Services by you or any third
                party you authorize to access or use such Content or Services;
              </li>
              <li>
                Any User Content you create, post, share or store on or through
                the Services or our pages or feeds on third party social media
                platforms;
              </li>
              <li>Any Feedback you provide;</li>
              <li>
                Your violation of these terms, and your violation of the rights
                of another;
              </li>
              <li>
                You agree to promptly notify Company of any third-party Claims,
                cooperate with the Company Parties in defending such Claims and
                pay all fees, costs and expenses associated with defending such
                Claims (including, but not limited to, attorneys’ fees and
                expenses, court costs, costs of settlement and costs of pursuing
                indemnification and insurance);
              </li>
              <li>
                You further agree that the Company Parties shall have control of
                the defense or settlement of any third-party Claims. This
                indemnity is in addition to, and not in lieu of, any other
                indemnities set forth in a written agreement between you and
                Company.
              </li>
            </StyledOL>
          </li>
          <li>
            <StyledSpan>Children.</StyledSpan>
            This Service is not directed at individuals under the age of
            eighteen (18) in the State of Massachusetts. Company does not
            knowingly collect personal information from any individual under the
            age of eighteen (18) in the State of Massachusetts at this Service.
          </li>
          <li>
            <StyledSpan>Entire Agreement.</StyledSpan>
            These Terms of Service incorporate by reference any notices
            contained on the Services, including within the{" "}
            <StyledLink href="/privacy-policy">Privacy Policy</StyledLink> and
            Refund Policy, and constitute the entire agreement with respect to
            access to and use of the Services and Content.
          </li>
          <li>
            <StyledSpan>Severability.</StyledSpan>
            If any provision of these Terms of Service is deemed unlawful, void,
            or unenforceable by a court of law exercising proper jurisdiction,
            that provision shall be deemed severed from the remaining provisions
            and shall not affect their validity and enforceability.
          </li>
          <li>
            <StyledSpan>No Waiver.</StyledSpan>
            No waiver of any provision hereof shall be valid unless in writing
            signed by the parties. Any failure to enforce any right or remedy
            hereunder shall not operate as a waiver of the right to enforce such
            right or remedy in the future or of any other right or remedy.
          </li>
          <li>
            <StyledSpan>No Class Actions. </StyledSpan>
            You and Company agree that there will be no class arbitration or
            arbitration in which an individual attempts to resolve a Dispute as
            a representative of another individual or group of individuals
          </li>
          <li>
            <StyledSpan>Governing Law and Venue - Massachusetts </StyledSpan>
            Any Dispute between the parties that is not subject to arbitration,
            shall be resolved in the state or federal courts of the State of
            Massachusetts and the United States, respectively, sitting in the
            State of Massachusetts.
          </li>
          <li>
            <StyledSpan>Contact </StyledSpan>
            <Typography>AC Advisory, LLC. D.B.A Qlink</Typography>
            <Typography>617 Tremont St. #4</Typography>
            <Typography>Boston, MA 02118</Typography>
          </li>
        </StyledOL>
      </Stack>
    </>
  );
};

export default TermsOfService;
