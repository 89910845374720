import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import NavLink from "../../../components/NavLink";
import { AuthContext } from "../../../context/Authcontext";
import Admins from "./admins/Admins";
import MyProfile from "./my-profile/MyProfile";
import Users from "./users/Users";
import Emails from "./emails/Emails";
const Wrapper = styled.div`
  padding-top: 60px;
`;

const Panel = () => {
  return (
    <Wrapper>
      <Grid container>
        <Grid item xs={2}>
          <List>
            <NavLink to="/admin/users">
              <ListItem disablePadding>
                <ListItemButton>
                  <Typography>Users</Typography>
                </ListItemButton>
              </ListItem>
            </NavLink>
            <NavLink to="/admin/admins">
              <ListItem disablePadding>
                <ListItemButton>
                  <Typography>Admins</Typography>
                </ListItemButton>
              </ListItem>
            </NavLink>
            <NavLink to="/admin/my-profile">
              <ListItem disablePadding>
                <ListItemButton>
                  <Typography>My profile</Typography>
                </ListItemButton>
              </ListItem>
            </NavLink>
            <NavLink to="/admin/emails">
              <ListItem disablePadding>
                <ListItemButton>
                  <Typography>Emails</Typography>
                </ListItemButton>
              </ListItem>
            </NavLink>
          </List>
        </Grid>
        <Grid item xs={10}>
          <Container maxWidth="xl">
            <Routes>
              <Route path="/users/*" element={<Users />} />
              <Route path="/admins/*" element={<Admins />} />
              <Route path="/my-profile/*" element={<MyProfile />} />
              <Route path="/emails/*" element={<Emails />} />
            </Routes>
          </Container>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default Panel;
