import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import AdminRow from "./AdminRow";
import usePaginate from "../../../../hooks/usePaginate";
import Pagination from "../../../../components/Pagination";
import api from "../../../../api/api";
const Admins = () => {
  const lookupCallback = useCallback((limit, skip) => {
    return api.admin.getAllAdmins({
      // limit,
      // skip,
      // // q: debouncedQuery.length > 0 ? debouncedQuery : undefined,
    });
  }, []);

  const pagination = usePaginate(lookupCallback, 10);
  console.log(pagination, "pag from admin");
  return (
    <Stack spacing={2}>
      <Box flex={1}>
        {pagination.isLoading && <>Loading</>}
        {!pagination.isLoading && pagination.results?.length === 0 && (
          <Typography color="error" alignText="center">
            Couldn&apos;t find any users with such id or email
          </Typography>
        )}

        {!pagination.isLoading && (
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                {/* TODO: MAP RESULT OF API CALL HERE  */}
              </TableRow>
            </TableHead>
            <TableBody>
              {!pagination.isLoading &&
                pagination.results.length !== 0 &&
                pagination.results.map((row) => (
                  <AdminRow key={row.id} admin={row} />
                ))}
            </TableBody>
          </Table>
        )}

        {!pagination.isLoading && pagination.results.length !== 0 && (
          <Pagination
            totalPages={pagination.totalPages}
            nextCallback={pagination.next}
            previousCallback={pagination.previous}
            currentPage={pagination.currentPage}
          />
        )}
      </Box>
    </Stack>
  );
};

export default Admins;
