import { Button, Card, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { AuthContext } from '../../../context/Authcontext'

const Wrapper = styled.div`
    width:100vw;
    height:100vh;
    display:flex;
    justify-content:center;
    align-items:center;
`
const StyledCard = styled(Card)`
        max-width: 40vw;
        min-width: 400px;
        padding:20px;
        `

const Login = () => {
  const { state: ContextState, login } = useContext(AuthContext);
  const { isLoginPending, isLoggedIn, loginError } = ContextState;
  
  const nav = useNavigate()

    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');

    const onEmailChange = useCallback(
        (ev) => setEmail(ev.target.value),
        []
      );
      const onPasswordChange = useCallback(
        (ev) => setPassword(ev.target.value),
        []
      );

    const handleSubmit = useCallback(
        (ev) => {
          ev.preventDefault();
          console.log(email,password);
          login(email,password)
        },
        [email, password, ]
      );

    useEffect(() => {
      console.log('isLoggdinn', isLoggedIn);
      if (isLoggedIn) nav('/admin/');
    }, [isLoggedIn]);   
  return (
    <Wrapper>
      <StyledCard variant="outlined">
        <form onSubmit={handleSubmit}>
            <Stack spacing={4} >
                <Typography variant="h4" textAlign="center" color="primary">ADMIN PANEL</Typography>
                <TextField
                    placeholder="username"
                    autoComplete="off"
                    required
                    value={email}
                    onChange={onEmailChange}
                 />
                <TextField
                    placeholder="password"
                    type="password"
                    required
                    value={password}
                    onChange={onPasswordChange}
                />
                <Button
                 variant='outlined'
                 type="submit" 
                >
                    Sign in
                </Button>
            </Stack>
        </form>
      </StyledCard>
    </Wrapper>
  )
}

export default Login
