import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import LoadingButton from "@mui/lab/LoadingButton";

import { useState, useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import TextField from "@mui/material/TextField";
import api from "../../../../api/api";

import { Typography } from "@mui/material";
function TemplateEditor({ body, CC, name, id, subject }) {
  const [secondary, setSecondary] = useState(false);
  const [bodyInputVal, setBodyInputVal] = useState("");
  const [CCInputVal, setCCInputVal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [subjectInput, setSubjectInput] = useState("");
  async function onSave() {
    setIsLoading(true);
    await api.admin.updateEmailTemplate(
      id,
      bodyInputVal,
      CCInputVal,
      subjectInput
    );
    setIsLoading(false);
  }
  useEffect(() => {
    setBodyInputVal(body);
    setCCInputVal(CC);
    setSubjectInput(subject);
  }, [body, CC, subject]);
  const handleCCChange = (event) => {
    setCCInputVal(event.target.value);
  };
  const handleSubjectChange = (event) => {
    setSubjectInput(event.target.value);
  };
  return (
    <>
      <div style={{ display: "flex", marginBottom: 20 }}>
        <TextField
          id="standard-basic"
          label="CC"
          variant="filled"
          value={CCInputVal}
          onChange={handleCCChange}
        />
        <TextField
          id="standard-basic"
          label="Subject  "
          variant="filled"
          value={subjectInput}
          onChange={handleSubjectChange}
        />
      </div>
      <ReactQuill
        style={{ minHeight: 200 }}
        theme="snow"
        value={bodyInputVal}
        onChange={setBodyInputVal}
      />
      <LoadingButton
        style={{ marginTop: 15 }}
        variant="contained"
        endIcon={<SaveIcon />}
        onClick={onSave}
        loading={isLoading}
      >
        Save
      </LoadingButton>
    </>
  );
}

export default TemplateEditor;
