import { Axios } from "axios";
const host = "https://qlinkit.io";
// const host = "http://localhost:8080";
// const host = "http://localhost:5000";

export const apiUrl = new Axios({
  baseURL: `${host}`,
  // withCredentials: true,
  headers: {
    "content-type": "application/json",
    // 'Access-Control-Allow-Origin': '*'
  },
});
export const adminApiUrl = new Axios({
  baseURL: `${host}/admins`,
  // withCredentials: true,
  headers: {
    "content-type": "application/json",
    // 'Access-Control-Allow-Origin': '*'
  },
});
