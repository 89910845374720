import { getToken } from "../helpers/auth";
import expand from "../helpers/expand";
import { adminApiUrl } from "../helpers/urls";

const admin = {
  /**
   * TODO: GET ALL USERS
   * TODO: UPDATE CERTAIN USER
   * TODO: GET ALL ADMINS  //>
   * TODO: GET ADMIN BY ID //>
   * TODO: UPDATE MY ACCOUNT AS ADMIN //>
   */
  async getAllUsers(options) {
    const token = getToken();

    const response = await adminApiUrl.get(`/users`, {
      params: options,
      headers: {
        authorization: token ? "Bearer " + token : undefined,
      },
    });
    console.log(expand(response));
    return expand(response);
  },
  async updateUser(state, id) {
    const token = getToken();
    const response = await adminApiUrl.patch(
      `/users/${id}`,
      JSON.stringify({ disabled: state }),
      {
        headers: {
          authorization: token ? "Bearer " + token : undefined,
        },
      }
    );
    return expand(response);
  },

  async getAllEmailTemplates() {
    const token = getToken();
    const response = await adminApiUrl.get("/emailTemplates", {
      headers: {
        authorization: token ? "Bearer " + token : undefined,
      },
    });
    return expand(response);
  },
  async updateEmailTemplate(id, body, CC, subject) {
    const token = getToken();
    const response = await adminApiUrl.post(
      "/updateEmailTemplate",
      JSON.stringify({
        id,
        body,
        CC,
        subject,
      }),
      {
        headers: {
          authorization: token ? "Bearer " + token : undefined,
        },
      }
    );
    return expand(response);
  },
  async updateAdminAccount(changes, id) {
    const token = getToken();

    const { name, email, password } = changes;
    const response = await adminApiUrl.patch(
      `/${id}`,
      JSON.stringify({ name, email, password }),
      {
        headers: {
          authorization: token ? "Bearer " + token : undefined,
        },
      }
    );

    return expand(response);
  },

  async login(email, password) {
    const response = await adminApiUrl.post(
      `/login`,
      JSON.stringify({ email, password })
      //    {
      //     withCredentials: true,
      //     headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
      //    },
    );

    return expand(response);
  },
  async logout() {
    const response = await adminApiUrl.post(``, {});

    return expand(response);
  },
  async getAllAdmins() {
    const token = getToken();
    console.log("TOOKEEENN", token);
    const response = await adminApiUrl.get(`/`, {
      headers: {
        authorization: token ? "Bearer " + token : undefined,
      },
    });
    return expand(response);
  },
  async getAdmin(id) {
    const token = getToken();
    const response = await adminApiUrl.get(`/${id}`, {
      headers: {
        authorization: token ? "Bearer " + token : undefined,
      },
    });
    return expand(response);
  },
  async getMe() {
    const token = getToken();
    console.log(token, "from request");
    const response = await adminApiUrl.get(`/me`, {
      headers: {
        authorization: token ? "Bearer " + token : undefined,
      },
    });
    return expand(response);
  },
};
export default admin;
