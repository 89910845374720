function expand(axiosResponse) {
    let data = axiosResponse.data;
    if (typeof data === "string") {
      data = JSON.parse(data.length === 0 ? null : data);
    }
    if (axiosResponse >= 400) {
      throw data;
    }
    if (data && data.error) throw data;
    return data;
  }
  
  export default expand;