import { Button, TableCell, TableRow } from '@mui/material'
import React from 'react'
const AdminRow = ({admin}) => {
  return (
    <TableRow>
    <TableCell>{admin.id}</TableCell>
    <TableCell>{admin.name}</TableCell>
    <TableCell>{admin.email}</TableCell>
    <TableCell>
    </TableCell>
    </TableRow>
  )
}

export default AdminRow
