import { Button, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import api from '../../../../api/api';
import { AuthContext } from '../../../../context/Authcontext';

const MyProfile = () => {

  const { state: ContextState, login } = useContext(AuthContext);
  const { isLoginPending, isLoggedIn, loginError,user } = ContextState;

  const [username,setUsername] = useState("");
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");

  const onUsernameChange = useCallback(
    (ev) => setUsername(ev.target.value),[])

  const onEmailChange = useCallback(
    (ev) => setEmail(ev.target.value),[])

  const onPasswordChange = useCallback(
    (ev) => setPassword(ev.target.value),[])

  const handleUpdate = useCallback(async(ev)=>{
    ev.preventDefault();
    const updates = {
      name:username!==''?username:undefined,
      email:email!==''?email:undefined,
      password:password!==''?password:undefined,
    }
    api.admin.updateAdminAccount(updates,user?.id).then(res=>console.log(res))
  },[username,email,password])  

  useEffect(()=>{
    console.log("after login",isLoggedIn,user)
    if(isLoggedIn) {
      setUsername(user.name);
      setEmail(user.email);
    }
  },[isLoggedIn,user])  
  return (
    <Stack spacing={2}>
      <Typography variant='h5'>Change Account Information</Typography>
      <form onSubmit={handleUpdate}>
      <Stack spacing={2} sx={{width:"fit-content", alignItems:"flex-start", justifyContent:"center"}}>
      <TextField
        value={username}
        onChange={onUsernameChange}
        placeholder='username'
      />
      <TextField
        placeholder="email"
        value={email}
        onChange={onEmailChange}
      />
      <TextField
        placeholder='password'
        value={password}
        onChange={onPasswordChange}
        type="password"
      />
      <Button  type="submit" variant="contained" sx={{width: "100%"}} >Save</Button>
      </Stack>
      </form>
    </Stack>
    
  )
}

export default MyProfile
