import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function createData(category, purpose, cookies) {
  return { category, purpose, cookies };
}

const rows = [
  createData(
    "Session & Security",
    "Authenticate users, protect user data and allow the website to deliver the services users expect, such as maintaining the content of their cart, or allowing file uploads.The website will not work properly if you reject or discard those cookies.",
    ["__cfduid (CloudFlare)"]
  ),
  createData(
    "Advertising & Marketing",
    "Used to make advertising more engaging to users and more valuable to publishers and advertisers, such as providing more relevant ads when you visit other websites that display ads or to improve reporting on ad campaign performance.Note that some third-party services may install additional cookies on your browser in order identify you.You may opt out of a third-party's use of cookies by visiting the Network Advertising Initiative opt-out page. The website will still work if you reject or discard those cookies.",
    ["__gads (Google)", "__gac (Google)"]
  ),
  createData(
    "Analytics",
    "Understand how visitors engage with our website, via Google Analytics. Learn more about Analytics cookies and privacy information.The website will still work if you reject or discard those cookies.",
    ["_ga (Google)", "_gat (Google)", "_gid (Google)", "_gac_* (Google)"]
  ),
];
const CookiesTable = () => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Category of Cookie</StyledTableCell>
            <StyledTableCell align="cener">Purpose</StyledTableCell>
            <StyledTableCell align="center">Cookies</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            return (
              <StyledTableRow key={index}>
                <TableCell align="left">{row.category}</TableCell>
                <TableCell align="center">{row.purpose}</TableCell>
                <TableCell align="center">
                  {row.cookies.map((cookie, index) => (
                    <div key={index}>{cookie}</div>
                  ))}
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CookiesTable;
