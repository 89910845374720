import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import api from "../../../../api/api";
import { useEffect } from "react";
import TemplateEditor from "./TemplateEditor";
function Emails() {
  const [secondary, setSecondary] = useState(false);
  const [value, setValue] = useState("");
  const [templates, setTemplates] = useState([]);
  useEffect(() => {
    (async () => {
      const templates = await api.admin.getAllEmailTemplates();
      if (templates && Array.isArray(templates)) {
        setTemplates(templates);
        console.log(templates);
      }
    })();
  }, []);
  return (
    <div>
      <div>
        <div>
          {templates.map((template, index) => {
            return (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>{template.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TemplateEditor
                    name={template.name}
                    body={template.body}
                    CC={template.CC}
                    id={template.id}
                    subject={template.subject}
                  />
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default Emails;
