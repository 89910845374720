//TODO: SAVE ACCESS TOKEN
// TODO: REMOVE ACCESS TOKEN WHEN LOGOUT
// TODO: get access token from localStorage
export const saveToken = (token)=>{
    localStorage.setItem("accessToken", JSON.stringify(token))
    return;
}
export const removeToken = ()=>{
    localStorage.removeItem("accessToken")
};
export const getToken = ()=>{
    return JSON.parse(localStorage.getItem("accessToken"))
};