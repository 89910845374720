import { Container, Stack } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import AppTheme from "../../styles/LandingPageTheme";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";

const StyledContainer = styled(Container)``;
const StyledHome = styled(Stack)``;
const Home = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home</title>
      </Helmet>
      <AppTheme>
        <StyledHome sx={{ maxHeight: "100%" }}>
          <StyledContainer maxWidth="xl">
            <Navbar />
          </StyledContainer>
          <Hero />
          <StyledContainer maxWidth="md">
            <Footer />
          </StyledContainer>
        </StyledHome>
      </AppTheme>
    </>
  );
};

export default Home;
