import { Link } from "react-router-dom";
import styled from "styled-components";

const NavLink = styled(Link)`
  all: unset;
  cursor: pointer;
  transition: opacity 100ms;
  &:hover {
    opacity: 0.8;
  }
`;


export default NavLink
