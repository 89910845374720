import { Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import styled, { css } from "styled-components";
const StyledCard = styled(Stack)`
  ${(props) => {
    return css`
      width: 620px;
      height: 2.5rem;
      box-shadow: 0px 4px 26px rgba(29, 37, 78, 0.34);
      border-radius: 0 8px 8px 0;
      border-left: 5px solid ${props.borderColor};
      background-color: #ffffff;
      padding: 1rem;
      flex-direction: row;
      align-items: center;
    `;
  }}
`;

const Card = ({ children, ...rest }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <StyledCard
      spacing={2}
      direction="row"
      {...rest}
      sx={{ width: isMobile && "250px !important" }}
    >
      {children}
    </StyledCard>
  );
};

export default Card;
