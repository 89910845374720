import expand from "../helpers/expand";
import { apiUrl } from "../helpers/urls";

const users = {
  async signup(name, email, password) {
    const response = await apiUrl.post(
      "/users/signup",
      JSON.stringify({ name, email, password })
    );
    console.log(response);
    return expand(response);
  },
  async betaSignup() {
    const response = await apiUrl.get("/beta/signup");
    console.log(response);
    return expand(response);
  },
};
export default users;
