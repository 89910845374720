import {
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Card from "../../../components/Card";
import { ReactComponent as BookmarksLogo } from "../../../assets/bookmarks.svg";
import { ReactComponent as TabsLogo } from "../../../assets/tabs.svg";
import { motion } from "framer-motion";
import TypeIt from "typeit-react";
import Cursor from "../../../components/Animations/Cursor";

const StyledSpan = styled("span")`
  background: linear-gradient(92.16deg, #42deb4 61.77%, #d259ff 77.8%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${"" /* font-size: 3.1rem; */}
  font-weight: 700;
`;

const Wrapper = styled(Stack)`
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;
const Phase1 = ({ setPhase }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 1,
        staggerChildren: 0.01,
      },
    },
  };

  const cards = {
    hidden: { opacity: 0 },
    show: (i) => ({
      opacity: 0.9,
      transition: {
        when: "beforeChildren",
        delayChildren: 0.5,
        staggerChildren: 0.05,
        duration: 2.9,
      },
    }),
  };
  const parentVariant = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { staggerChildren: 1 } },
  };

  const childrenVariant = {
    initial: { opacity: 0, x: 50 },
    animate: { opacity: 1, x: 0 },
  };

  const item = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
  };

  const cardsData = [
    {
      title: "Qlink Contact",
      type: "Bookmarked",
      icon: () => <BookmarksLogo />,
      borderColor: "#42DEB4",
    },
    {
      title: "Qlink Beta",
      type: "Tabbed",
      icon: () => <TabsLogo />,
      borderColor: "#D259FF",
    },
  ];

  const Allcards = [
    {
      title: "Qlink Contact",
      type: "Bookmarked",
      icon: () => <BookmarksLogo />,
      borderColor: "#42DEB4",
    },
    {
      title: "Qlink Beta",
      type: "Tabbed",
      icon: () => <TabsLogo />,
      borderColor: "#D259FF",
    },
    {
      title: "Beta signup",
      type: "Tabbed",
      icon: () => <TabsLogo />,
      borderColor: "#D259FF",
    },
    {
      title: "Beta privacy policy",
      type: "Tabbed",
      icon: () => <TabsLogo />,
      borderColor: "#D259FF",
    },
  ];
  const [betaTyped, setBetaTyped] = useState(false);
  const [cardsFinished, setCardsFinished] = useState(false);
  const [showCards, setShowCards] = useState(false);
  const [animateSearch, setAnimateSearch] = useState(false);
  const [narrowList, setNarrowList] = useState(false);
  const [finished, setFinished] = useState(false);
  const [selectCard, setSelectCard] = useState(false);

  const cardRef = useRef();
  const [x, setX] = useState(10);
  const [y, setY] = useState(10);

  const getPositionCard = () => {
    const x = cardRef.current.offsetLeft;
    const y = cardRef.current.offsetTop;
    setX(x + 30);
    setY(y - 30);
  };
  // Get the position of the red box in the beginning
  useEffect(() => {
    let timer;
    if (selectCard) {
      timer = setTimeout(() => {
        getPositionCard();
      }, 500);
    }
    return () => clearTimeout(timer);
  }, [selectCard]);

  // Re-calculate X and Y of the red box when the window is resized by the user
  useEffect(() => {
    window.addEventListener("resize", getPositionCard);
  }, []);

  return (
    <Wrapper
      sx={{ alignItems: "center", justifyContent: "flex-start" }}
      spacing={2}
      flexGrow={1}
    >
      <Cursor color="#000000" x={x} y={y} />

      <Typography
        variant="h1"
        sx={{
          fontSize: isMobile && "1.5rem",
        }}
        component={motion.div}
        animate={{
          opacity: [0, 0.2, 0.5, 0.7, 0.8, 1],
        }}
      >
        Link instantly with a quick keystroke:
      </Typography>
      <motion.div initial="initial" animate="animate" variants={parentVariant}>
        <Stack
          direction="row"
          component={motion.div}
          variants={container}
          initial="show"
          animate="show"
          alignItems="center"
          spacing={1}
        >
          {betaTyped ? (
            <Typography
              variant="h1"
              component={motion.div}
              variants={container}
              initial="hidden"
              animate={"show"}
              transition={{ ease: "easeInOut", duration: 1 }}
              sx={{
                fontSize: isMobile ? "0.85rem !important" : "3.1rem",
                display: "inline-block",
              }}
            >
              Signup for our{" "}
              {!cardsFinished && (
                <StyledSpan
                  sx={{
                    fontSize: isMobile ? "0.85rem !important" : "3.1rem",
                  }}
                >
                  beta//
                </StyledSpan>
              )}
            </Typography>
          ) : (
            <TypeIt
              options={{
                speed: 200,
                startDelay: 250,
                afterComplete: () => {
                  console.log("completed");
                  setTimeout(() => {
                    setBetaTyped(true);
                    setShowCards(true);
                  }, 1000);
                },
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontSize: isMobile && "1rem",
                  display: "inline-block",
                }}
              >
                Signup for our beta//
              </Typography>
            </TypeIt>
          )}
          {animateSearch && !cardsFinished && (
            <TypeIt
              options={{
                speed: 200,
                startDelay: 500,
                afterComplete: () => {
                  console.log("completed Search");
                  setNarrowList(true);
                },
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontSize: isMobile && "0.85rem",
                  display: "inline-block",
                }}
              >
                QLink
              </Typography>
            </TypeIt>
          )}

          {cardsFinished && (
            <Link
              component={motion.div}
              sx={{
                fontWeight: 700,
                fontSize: isMobile ? "0.85rem" : "3.1rem",
                textDecorationLine: "underline",
                color: "#2AA5FC",
              }}
              animate={{ opacity: [0, 0.4, 0.8, 1] }}
              onAnimationComplete={() => {
                console.log("phase 2 should start");
                setTimeout(() => {
                  setPhase((prev) => (prev + 1) % 3);
                  // setPhase(0);
                }, 1000);
              }}
            >
              beta
            </Link>
          )}
        </Stack>
        {betaTyped && showCards && !cardsFinished && (
          <>
            <Wrapper
              spacing={3}
              mt={1}
              variants={cards}
              initial="hidden"
              animate="show"
            >
              {!narrowList
                ? Allcards.map((card, i) => (
                    <Card
                      key={i}
                      borderColor={card.borderColor}
                      custom={i}
                      variants={cards}
                      component={motion.div}
                      initial="initial"
                      onAnimationComplete={() => {
                        setTimeout(() => {
                          setAnimateSearch(true);
                        }, 1000);
                      }}
                    >
                      <Typography
                        variant="h2"
                        sx={{
                          fontWeight: "700",
                          fontSize: isMobile ? "0.7rem" : "1.2rem",
                          display: "inline-block",
                          flexGrow: 1,
                          wordBreak: "none",
                        }}
                      >
                        {card.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: isMobile && "0.5rem",
                        }}
                      >
                        {card.type}
                      </Typography>
                      <div style={{ flexGrow: 2 }}></div>
                      {card.icon()}
                    </Card>
                  ))
                : cardsData.map((card, i) => (
                    <div key={i}>
                      <Card
                        borderColor={card.borderColor}
                        custom={i}
                        variants={cards}
                        component={motion.div}
                        initial="initial"
                        animate={[
                          cards.show,
                          selectCard && {
                            scale: i === 1 ? [1, 1.1, 0.9, 1] : 1,
                            transition: { delay: 0.5 },
                          },
                        ]}
                        transition={{ duration: 1, delay: i * 0.1 }}
                        onAnimationComplete={() => {
                          // setTimeout(() => {
                          //   setCardsFinished(true);
                          // }, 4000);
                          setTimeout(() => {
                            setSelectCard(true);
                          }, 1000);
                          setTimeout(() => {
                            setCardsFinished(true);
                          }, 4000);
                        }}
                      >
                        <Typography
                          variant="h2"
                          sx={{
                            fontWeight: "700",
                            fontSize: isMobile ? "0.7rem" : "1.2rem",
                            display: "inline-block",
                            flexGrow: 1,
                            wordBreak: "none",
                          }}
                        >
                          {card.title}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: isMobile && "0.5rem",
                          }}
                        >
                          {card.type}
                        </Typography>
                        <div style={{ flexGrow: 2 }}></div>
                        {card.icon()}
                      </Card>
                      {i == 1 && <div ref={cardRef}></div>}
                    </div>
                  ))}
            </Wrapper>
          </>
        )}
      </motion.div>
    </Wrapper>
  );
};

export default Phase1;
