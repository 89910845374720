import { Link, List, ListItemText, Stack, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import CookiesTable from "./components/CookiesTable";

const StyledSpan = styled("span")`
  font-weight: 700;
`;

const StyledOL = styled("ol")`
  & > li {
    padding: 0.5rem;
  }
`;
const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy</title>
      </Helmet>
      <Stack
        alignItem="center"
        spacing={2}
        sx={{ maxWidth: "100%", padding: "1rem" }}
      >
        <Typography variant="h2" sx={{ textAlign: "center" }}>
          PRIVACY POLICY
        </Typography>

        <Typography variant="p" sx={{ fontWeight: "700" }}>
          Last Updated: November 23, 2022 
        </Typography>

        <Typography variant="p">
          This privacy notice applies to any AC Advisory, LLC., D.B.A “Qlink”
          Service (collectively "Services") where this privacy notice is
          referenced, regardless of how you access or use them, including
          through mobile devices. This Privacy Policy incorporates by reference
          the Qlink terms and conditions. Note that we combine the information
          we collect from you from the Service, through the Service generally,
          or offline. This privacy policy has been compiled to better serve
          those who are concerned with how their ‘Personal Information’ is being
          used online. Personal Information, as described in US privacy law and
          information security, is information that can be used on its own or
          with other information to identify, contact, or locate a single
          person, or to identify an individual in context.
        </Typography>

        <Typography variant="p" sx={{ fontWeight: 700 }}>
          PLEASE READ OUR PRIVACY POLICY CAREFULLY TO GET A CLEAR UNDERSTANDING
          OF HOW WE COLLECT, USE, PROTECT OR OTHERWISE HANDLE YOUR PERSONAL
          INFORMATION IN ACCORDANCE WITH OUR SERVICE. BY USING THIS SERVICE, YOU
          EXPRESSLY ACKNOWLEDGE AND AGREE TO OUR COLLECTION, USE, SHARING, AND
          PROTECTION OF YOUR PERSONAL INFORMATION AS SUMMARIZED BELOW AND AS
          FULLY DESCRIBED IN OUR USER PRIVACY NOTICE.
        </Typography>
        <StyledOL>
          <li>
            <StyledSpan>Changes </StyledSpan>. We may amend this privacy notice
            at any time by posting the amended version on this Service including
            the effective date of the amended version.
          </li>
          <li>
            <StyledSpan>
              Information We Collect & How We Process Data{" "}
            </StyledSpan>
            We are a data Processor and Collector. We process data received from
            our services and take proportionate precaution in storing such
            information for short periods on our secure servers until
            permanently destroyed. Our servers and offices are located in the
            United States, so your information may be transferred to, stored, or
            processed in the United States. When you contact us or interact with
            our Service we collect information that alone or in combination with
            other information could be used to identify you ("Personal Data") as
            follows:
            <StyledOL style={{ listStyleType: "lower-alpha" }}>
              <li>
                We may collect your name, email address, phone number, and
                payment information when you register for our Service, sign up
                for our mailing list, or otherwise communicate with us. We may
                also collect any communications between you and Qlink and any
                other information you provide to the Qlink.
              </li>
              <li>
                We collect information from Qlink product features, including
                the content of drafts or snippets you create in the Service and
                read receipts.
              </li>
              <li>
                When you sign in to the Service, we collect and store encrypted
                Google authentication tokens.
              </li>
              <li>
                When you visit, use and interact with the Service, we may
                receive certain information about your visit, use or
                interactions. For example, we may monitor the number of people
                that visit our Service, peak hours of visits, which page(s) are
                visited on our Service, the domains our visitors come from, and
                which browsers people use to access and visit our Service, broad
                geographical information, and Service-navigation pattern. In
                particular, the following information is created and
                automatically logged in our systems:
              </li>
              <li>
                Information that your browser automatically sends whenever you
                visit the Service ("log data"). Log data includes your Internet
                Protocol ("IP") address (so we understand which country you are
                connecting from when you visit the Service), browser type and
                settings, the date and time of your request, and how you
                interacted with the Service.
              </li>
              <li>
                Includes the operating system and browser you are using.
                Information collected may depend on the type of device you use
                and its settings.
              </li>
              <li>
                We collect information about how you use our Service, such as
                the types of content that you view or engage with, the features
                you use, the actions you take, and the time, frequency and
                duration of your activities.
              </li>
              <li>
                We collect and store anonymous information about emails you send
                and receive through the Service. This includes numeric
                identifiers and timestamps, but does not include Email Content
                Data or other information that could identify the sender, the
                recipient or the subject of the email.
              </li>
              <li>
                Our Service collects information that identifies, relates to,
                describes, references, is capable of being associated with, or
                could reasonably be linked, directly or indirectly, with a
                particular consumer or device (”personal information”). In
                particular, our Service has collected the following categories
                of personal information from its consumers:
              </li>
              <li>
                <StyledSpan>Category A: </StyledSpan> identifiers
                <StyledOL style={{ listStyleType: "lower-roman" }}>
                  <li>
                    Examples: A real name Information, Internet Protocol
                    address, email address, or other similar identifiers, device
                    information.
                  </li>
                  <li>
                    Collected: <StyledSpan>YES</StyledSpan>
                  </li>
                </StyledOL>
              </li>
              <li>
                <StyledSpan>Category B: </StyledSpan> Personal information
                categories.
                <StyledOL style={{ listStyleType: "lower-roman" }}>
                  <li>
                    Examples: A name, signature, address, telephone number, bank
                    account number, credit card number, debit card number, or
                    any other financial information.
                  </li>
                  <li>
                    Collected: <StyledSpan>YES</StyledSpan>
                  </li>
                </StyledOL>
              </li>
              <li>
                <StyledSpan>Category C: </StyledSpan> Protected classification
                characteristics under applicable laws.
                <StyledOL style={{ listStyleType: "lower-roman" }}>
                  <li>
                    Examples: Age (40 years or older), race, color, ancestry,
                    national origin, citizenship, religion or creed, marital
                    status, medical condition, physical or mental disability,
                    sex (including gender, gender identity, gender expression,
                    pregnancy or childbirth and related medical conditions),
                    sexual orientation, veteran or military status, genetic
                    information (including familial genetic information).
                  </li>
                  <li>
                    Collected: <StyledSpan>NO</StyledSpan>
                  </li>
                </StyledOL>
              </li>
            </StyledOL>
          </li>
          <li>
            We obtain the categories of personal information listed above from
            the following categories of sources:
            <StyledOL>
              <li>
                <StyledSpan>Directly from you. </StyledSpan> For example, from
                forms you complete on our Service.
              </li>
              <li>
                <StyledSpan>Indirectly from you.</StyledSpan> For example, from
                observing your actions on our Service or interactions with our
                advertisers.
              </li>
            </StyledOL>
          </li>
          <li>
            <StyledSpan>
              Categories of Personal Information We Collect{" "}
            </StyledSpan>
            Personally Identifiable Information is information that identifies a
            specific person. When you engage in certain activities via the
            Company Service, including but not limited to creating an account,
            sending feedback, or otherwise participating in the Company Service
            (collectively, “Identification Activities”), we will ask you to
            provide certain information about yourself. We also collect the
            following:
            <StyledOL style={{ listStyleType: "lower-roman" }}>
              <li>Identity Data</li>
              <li>Contact Data</li>
              <li>Technical Data</li>
              <li>Usage Data</li>
              <li>Transaction Data</li>
              <li>Financial Data</li>
              <li>Profile Data</li>
            </StyledOL>
          </li>
          <li>
            <StyledSpan>Non-Personally Identifiable Information. </StyledSpan>
            Non-Personally Identifiable Information is information that does not
            identify a specific person. This type of information may include
            things like the Uniform Resource Locator (“URL”), your Internet
            Protocol (“IP”) address, mobile carrier information, mobile device
            information, or general and/or aggregated location data that does
            constitute Personally Identifiable Information.
            <StyledOL style={{ listStyleType: "lower-alpha" }}>
              <li>
                We, and/or our authorized Third-Party Service Providers, may
                automatically collect this information when you visit or use the
                Company Service using electronic tools like Cookies and Web
                beacons or Pixel tags, as described below in this Privacy
                Policy. Most of the other information we collect for mobile,
                such as your mobile device identification and mobile device
                type, the request type, your mobile carrier, your mobile carrier
                user identification, and the content of your request, does not
                by itself identify you to Company, though it may be unique or
                consist of or contain information that you consider personal.
              </li>
              <li>
                We use Non-Personally Identifiable Information to troubleshoot,
                administer the Company Service, analyze trends, gather
                demographic information, comply with applicable law, and
                cooperate with law enforcement activities. We will also share
                this information with our authorized Third-Party Service
                Providers to measure the overall effectiveness of our products
                and services.
              </li>
            </StyledOL>
          </li>
          <li>
            <StyledSpan>
              Personal information collected from Third Parties
            </StyledSpan>
            We collect and use demographic and other information that is
            publicly available in an applicable jurisdiction, additional contact
            information, as allowed by applicable national laws.
            <StyledOL style={{ listStyleType: "lower-alpha" }}>
              <li>
                We allow you to share information with social media sites, or
                use social media sites to create your account or to connect your
                account with the respective social media Service. Those social
                media sites may give us automatic access to certain personal
                information retained by them about you (e.g., content viewed by
                you, content liked by you, and information about the
                advertisements you have been shown or have clicked on, etc.). If
                you provide us with access to any Service with video content,
                then you agree that we can share your video viewing with, or
                obtain information about your video viewing from, third-party
                social media sites for at least two years or until you withdraw
                authorization or connection to the social media Service.
              </li>
              <li>
                You control the personal information you allow us to have access
                to through the privacy settings on the applicable social media
                Service and the permissions you give us when you grant us access
                to the personal information retained by the respective social
                media Service about you. By associating an account managed by a
                social media Service with your account and authorizing us to
                have access to this information, you agree that we can collect,
                use and retain the information provided by these social media
                sites in accordance with this privacy notice. We may also use
                plug-ins or other technologies from various social media sites.
                If you click on a link provided via a social media plug in, you
                are voluntarily establishing a connection with that respective
                social media Service.
              </li>
              <li>
                If you give us personal information about someone else, you must
                do so only with that person’s authorization. You agree to inform
                them how we collect, use, disclose, and retain their personal
                information according to our privacy notice.
              </li>
            </StyledOL>
          </li>
          <li>
            <StyledSpan>Information Collected from All Users </StyledSpan>
            Information you provide to us: For all Users we collect Personal
            Data when you voluntarily provide such information to the Services,
            such as when you register for access to the Services, contact us
            with inquiries, respond to one of our surveys or browse or use
            certain parts of the Services. The Personal Data we may collect
            includes without limitation your name, address, email address and
            any other information that you choose to provide and/or that enables
            Users to be personally identified.
            <StyledOL style={{ listStyleType: "lower-alpha" }}>
              <li>
                Information we automatically collect: We also automatically
                collect certain technical data that is sent to us from the
                mobile device and/or browser through which you access the
                Services ("Automatic Data"). Automatic Data, includes without
                limitation, a unique identifier associated with your access
                device and/or browser (including, for example, your Internet
                Protocol (IP) address) characteristics about your access device
                and/or browser, statistics on your activities on the Services,
                information about how you came to the Services and data
                collected through Cookies, Pixel Tags, Local Shared Objects, Web
                Storage and other similar technologies. You can find out more
                information about how we use Cookies and other similar tracking
                technologies in our Cookie Policy.
              </li>
              <li>
                When you register for the Services or otherwise submit Personal
                Data to us, we may associate other Non-Personal Data (including
                Non-Personal Data we collect from third parties) with your
                Personal Data. At such instance, we will treat any such combined
                data as your Personal Data until such time as it can no longer
                be associated with you or used to identify you.
              </li>
            </StyledOL>
          </li>
          <li>
            <StyledSpan>Cookies </StyledSpan>
            We use cookies, web beacons, unique identifiers, and similar
            technologies to collect information about the pages you view, the
            links you click, and other actions you take when using our Services,
            within our advertising or email content. A cookie is a small data
            file that we transfer to your computer’s hard disk for
            record-keeping purposes. We use both persistent cookies that remain
            on your computer or similar device (such as to save your
            registration ID and login password for future logins to the Service
            and to track your compliance with the Qlink Terms and Conditions)
            and session ID cookies, which expire at the end of your browser
            session (for example, to enable certain features of the Service. You
            can control the use of cookies at the individual browser level, but
            if you choose to disable cookies, it may limit your use of certain
            features or functionality of the Service.
            <StyledOL style={{ listStyleType: "lower-alpha" }}>
              <li>
                We also use third-party services such as Google Analytics, who
                set and use their own cookies to identify visitors and provide
                their own contextual services. For more information regarding
                those third-party providers and their Cookie Policy, please see
                the relevant references in the Third-Party Service
                Providers section.
              </li>
              <li>
                Here is an overview of the cookies that may be stored on your
                device when you visit our website:
              </li>
              {/* TODO: PLACE COOKIES TABLE HERE */}
              <CookiesTable />
            </StyledOL>
          </li>
          <li>
            <StyledSpan>How We Use Your Personal Data </StyledSpan>
            We collect and use the Personal Data we collect in a manner that is
            consistent with this Privacy Policy, and applicable privacy laws. We
            may use the Personal Data as follows:
            <StyledOL style={{ listStyleType: "lower-alpha" }}>
              <li>
                <StyledSpan>Access and Use </StyledSpan>
                If you provide Personal Data in order to obtain access to or use
                of the Services or any functionality thereof, we will use your
                Personal Data to provide you with access to or use of the
                Services or functionality and to analyze your use of such
                Services or functionality. For instance, if you supply Personal
                Data relating to your identity or qualifications to use certain
                portions of the Services, we will use that information to make a
                decision as to granting you access to use such Services and to
                assess your ongoing qualification to use such Services.
              </li>
              <li>
                <StyledSpan>Third Party Disclosure </StyledSpan>
                Except as explicitly mentioned above, we do not sell, trade, or
                otherwise transfer your personal data to third parties. We may
                share or disclose aggregated or de-identified information, for
                research purposes, or to discuss trends or statistics with
                third-parties.
              </li>

              <li>
                <StyledSpan>Internal Business Purposes. </StyledSpan>
                We may use your Personal Data for internal business purposes,
                including without limitation, to help us improve the content and
                functionality of the Services, to better understand our Users,
                to improve the Services, to protect against, identify or address
                wrongdoing, to enforce our Terms and Conditions, to manage your
                account and provide you with customer service, and to generally
                manage the Services and our business.
              </li>

              <li>
                <StyledSpan>Use of Interest-Based Data. </StyledSpan>
                We sometimes make inferences about the type of services you may
                be interested in. We may use these inferences to help target
                advertising or customize recommendations to you, including on
                behalf of Servicers. We may do this on an aggregated or
                generalized basis.
              </li>

              <li>
                <StyledSpan>Email Marketing </StyledSpan>
                We may use provided email addresses for marketing future
                products upon consent.
              </li>

              <li>
                <StyledSpan>Other Purposes. </StyledSpan>
                If we intend to use any Personal Data in any manner that is not
                consistent with this Privacy Policy, you will be informed of
                such anticipated use prior to or at the time the Personal Data
                is collected or we will obtain your consent subsequent to such
                collection but prior to such use.
              </li>
              <li>
                <StyledSpan>Aggregated Personal Data. </StyledSpan>In an ongoing
                effort to understand and serve our Users better, we often
                conduct research on our customer demographics, interests and
                behavior based on Personal Data and other information that we
                have collected. This research is typically conducted on an
                aggregate basis only that does not identify you. Once Personal
                Data is in an aggregated form, for purposes of this Privacy
                Policy, it becomes Non-Personal Data.
              </li>
            </StyledOL>
          </li>
          <li>
            <StyledSpan>Specific Reason for Use (above). </StyledSpan>
            <StyledOL style={{ listStyleTypes: "lower-alpha" }}>
              <li>
                If you provide Personal Data for a certain purpose, we may use
                the Personal Data in connection with the purpose for which it
                was provided. For instance, if you contact us by e-mail, we will
                use the Personal Data you provide to answer your question or
                resolve your problem and will respond to the email address from
                which the contact came.
              </li>
              <li>
                We use your personal information to provide and improve our
                Services, provide you with a personalized experience on our
                sites, contact you about your account and our Services, provide
                you customer service, provide you with personalized advertising
                and marketing, and to detect, prevent, mitigate and investigate
                fraudulent or illegal activities.
              </li>
              <li>
                We use the personal information we collect from you for a range
                of different business purposes and according to different legal
                bases of processing.
              </li>
            </StyledOL>
          </li>
          <li>
            <StyledSpan>
              How We Disclose and Transfer Your Personal Data.
            </StyledSpan>
            e will not sell your Personal Data to third parties, including third
            party advertisers. There are certain circumstances in which we may
            disclose, transfer or share your Personal Data with certain third
            parties without further notice to you, as set forth in this Privacy
            Policy.
          </li>
          <li>
            <StyledSpan>Service Providers. </StyledSpan>
            We may share your Personal Data with contractors and service
            providers who process Personal Data on Qlink to perform certain
            business-related functions. We are not responsible for the actions
            of these Servicers, or their Third-Party Servicers (or other
            downstream recipients of your Personal Data), with respect to your
            Personal Data. It is important that you review the applicable
            policies of the Servicers, and if applicable and available, their
            appointed Third-Party Servicers, before providing Personal Data or
            other information in connection with that service.
            <StyledOL style={{ listStyleTypes: "lower-alpha" }}>
              <li>
                Similarly, if you are a member of a Servicer's organization
                within Qlink, your Personal Data will be available to the
                Servicer and shared with those Third-Party Servicers granted
                permission by the Servicer to view all members of the Servicer's
                organization. 
              </li>
            </StyledOL>
          </li>
          <li>
            <StyledSpan>
              Google, Social Media and Other Third Party Connections.
            </StyledSpan>
            Connecting Your Qlink account to social media services. You can
            connect your Qlink account to your accounts on third party services
            like, Google, and Linkedin, in which case we may collect, use,
            disclose, transfer and store/retain information relating to your
            account with such third-party services in accordance with this
            Privacy Policy.
          </li>
          <li>
            <StyledSpan>Legal Requirements </StyledSpan>
            We may disclose your Personal Data if required to do so by law in
            order to (for example) respond to a subpoena or request from law
            enforcement, a court or a government agency (including in response
            to public authorities to meet national security or law enforcement
            requirements), or in the good faith belief that such action is
            necessary to (a) comply with a legal obligation, (b) protect or
            defend our rights, interests or property or that of third parties,
            (c) prevent or investigate possible wrongdoing in connection with
            the Services, (d) act in urgent circumstances to protect the
            personal safety of Users of the Services or the public, or (e)
            protect against legal liability.
          </li>
          <li>
            <StyledSpan>Security </StyledSpan>
            Your personal information is contained behind secured networks and
            is only accessible by a limited number of persons who have special
            access rights to such systems, and are required to keep the
            information confidential. If you provide us with your credit card
            information, the data is fully encrypted using AWS cloud based
            databases. Although no method of transmission over the Internet or
            electronic storage is 100% secure, we follow all requirements and
            implement additional generally accepted industry standards. We
            implement a variety of security measures when a user places an order
            enters, submits, or accesses their information to maintain the
            safety of your personal information. All transactions are processed
            through a gateway provider and are not stored or processed on our
            servers.
          </li>
          <li>
            <StyledSpan>Your Rights & Data Retention </StyledSpan>
            We retain your Personal Data for as long as necessary to provide you
            with our Services, or for other important purposes such as complying
            with legal obligations, resolving disputes, and enforcing our
            agreements. If you have an account with us, we will typically retain
            your Personal Data for a period not to exceed of thirty (30) days
            after you have requested that your account is closed or if it's been
            inactive.
            <StyledOL style={{ listStyleType: "lower-alpha" }}>
              <li>
                <StyledSpan>Account & Contact Data </StyledSpan>
                we will only retain such data as long as necessary for the
                purpose for which it was collected, as laid out in this policy,
                including any legal retention period, or as long as necessary to
                carry out a legitimate and reasonable promotion of our products
                and services.
              </li>
              <li>
                <StyledSpan>Browser Data: </StyledSpan>
                we may retain this data for a maximum of 1 month, unless we need
                to keep it in relation with a legitimate concern related to the
                security or performance of our services, or as required by law.
                Any server-side session information is kept only for 1 month
                when it is actively used, otherwise it is discarded after 7
                days.
              </li>
              <li>
                <StyledSpan>Customer Database </StyledSpan>
                we will only retain this data as long as necessary for providing
                the services you subscribed to. For databases hosted on the
                Qlink Cloud, if you cancel the service your database is kept
                deactivated for 3 weeks (the grace period during which you can
                change your mind), and then destroyed. For databases uploaded to
                the Qlink Database Upgrade website, your database is kept for up
                to 4 months after the last successful upgrade, and may be
                deleted earlier upon request.
              </li>
            </StyledOL>
          </li>
          <li>
            <StyledSpan>Specific Rights </StyledSpan>
            Data protection law provides you with rights in respect of Personal
            Data that we hold about you, including the right to request a copy
            of the Personal Data, request that we rectify, restrict or delete
            your Personal Data, object to profiling and unsubscribe from
            marketing communications.
            <StyledOL style={{ listStyleType: "lower-alpha" }}>
              <li>
                Registered Qlink Users may update their choices regarding the
                types of communications you receive from us through your online
                account. You also may opt-out of receiving marketing emails from
                us by following the opt-out instructions provided in those
                emails. Please note that we reserve the right to send you
                certain communications relating to your account or use of the
                Service (for example, administrative and service announcements)
                via email and other means and these transactional account
                messages may be unaffected if you opt-out from receiving
                marketing communications.
              </li>
              <li>
                All Users may request access to or correction of any Personal
                Information we have about them or close their account and/or
                request deletion of all Personal Information we have about them.
                In certain jurisdictions, Users may have certain rights with
                regard to their Personal Information. We will honor User
                requests to the extent we can reasonably do so and as required
                by law, but some information will remain on the Services, such
                as information you posted publicly.
              </li>
              <li>
                Upon request Qlink will provide you with information about
                whether we hold any of your personal information. You are
                responsible for maintaining the accuracy of the information you
                submit to us, such as your contact information. You may access,
                correct, or request deletion of your personal information by
                making updates to that information or by contacting Qlink
                through your online account.
              </li>
              <li>
                If you request to access all personal information you’ve
                submitted, we will respond to your request to access within 30
                days or as otherwise required by law. If your information is
                deleted, then your account may become deactivated. If your
                account is deactivated or you ask to close your account, you
                will no longer be able to use the Service. If you would like to
                close your account in our system, you can do so through the
                Qlink Service (once you have logged in, visit settings / user
                settings, and then click on the close my account link).
              </li>
              <li>
                We will use commercially reasonable efforts to honor your
                requests for deletion; however, certain information will
                actively persist on the Service even if you close your account,
                including information in your Work Diaries and messages you
                posted to the Service. In addition, the rights described above
                may be limited, for example, if fulfilling your request would
                reveal personal information about another person, or if you ask
                us to delete information we are required by law to keep or have
                compelling legitimate interests in keeping (such as for fraud
                prevention purposes).
              </li>
              <li>
                Your Personal Information may remain in our archives and
                information you update or delete, or information within a closed
                account, may persist internally for our administrative purposes,
                to the extent permitted by law. In addition, we typically will
                not remove information you posted publicly through or on the
                Service. Bear in mind that neither you nor Qlink can delete all
                copies of information that has been previously shared with
                others on the Service.
              </li>
            </StyledOL>
          </li>
          <li>
            <StyledSpan>Limit the Personal Data You Provide. </StyledSpan>
            You can browse the Services without providing any Personal Data
            (other than Automatic Data to the extent it is considered Personal
            Data under applicable laws) or with limiting the Personal Data you
            provide. If you choose not to provide any Personal Data or limit the
            Personal Data you provide, you may not be able to use certain
            functionality of the Services.
          </li>
          <li>
            <StyledSpan>Personal Data Provided to Others. </StyledSpan>
            This Privacy Policy does not apply to any Personal Data that you
            provide to another User or visitor through the Services or through
            any other means.
          </li>
          <li>
            <StyledSpan>Third Party Links. </StyledSpan>
            This Privacy Policy applies only to the Services. The Services may
            contain links to other Websites not operated or controlled by us
            (the "Third Party Sites"). The policies and procedures we described
            here do not apply to the Third Party Sites. The links from the
            Services do not imply that we endorse or have reviewed the Third
            Party Sites. We suggest contacting those sites directly for
            information on their privacy policies.
          </li>
          <li>
            <StyledSpan> Third Party Privacy Practices </StyledSpan>
            This privacy notice addresses only our use and handling of personal
            information we collect from you in connection with providing you our
            Services. If you disclose your information to a third party, or
            visit a third-party website via a link from our Services, their
            privacy notices and practices will apply to any personal information
            you provide to them or they collect from you. We cannot guarantee
            the privacy or security of your personal information once you
            provide it to a third party and we encourage you to evaluate the
            privacy and security policies of your trading partner before
            entering into a transaction and choosing to share your personal
            information. This is true even where the third parties to whom you
            disclose personal information are bidders, buyers or sellers on our
            Service.
          </li>

          <li>
            <StyledSpan>
              {" "}
              COPPA - Children's Online Privacy Protection Act
            </StyledSpan>
            We do not knowingly collect Personal Data from children under the
            age of eighteen (18). If you are under the age of eighteen (18),
            please do not submit any Personal Data through the Services. We
            encourage parents and legal guardians to monitor their children's
            Internet usage and to help enforce our Privacy Policy by instructing
            their children never to provide Personal Data through the Services
            without their permission. If you have reason to believe that a child
            under the age of 18 has provided Personal Data to us through the
            Services, please <Link>contact us</Link> and we will endeavor to
            delete that information from our databases.
          </li>

          <li>
            <StyledSpan>International Privacy Laws </StyledSpan>
            If you are visiting the Services from outside the United States,
            please be aware that you are sending information (including Personal
            Data) to the United States where our servers are located. That
            information may then be transferred within the United States or back
            out of the United States to other countries outside of your country
            of residence, depending on the type of information and how it is
            stored by us. These countries (including the United States) may not
            necessarily have data protection laws as comprehensive or protective
            as those in your country of residence; however, our collection,
            storage and use of your Personal Data will at all times continue to
            be governed by this Privacy Policy.
          </li>

          <li>
            <StyledSpan>Modifications </StyledSpan>
            We reserve the right, in our sole discretion, to update or modify
            this Privacy Policy at any time (collectively, "Modifications").
            Modifications to this Privacy Policy will be posted to the Service
            with a change to the "Updated" date at the top of this Privacy
            Policy. In certain circumstances Qlink may, but need not, provide
            you with additional notice of such Modifications, such as via email
            or with in-Service notifications.
            <StyledOL style={{ listStyleType: "lower-alpha" }}>
              <li>
                Please review this policy periodically, and especially before
                you provide any Personal Data. This Privacy Policy was updated
                on the date indicated above. Your continued use of the Services
                following the effectiveness of any Modifications to this Privacy
                Policy constitutes acceptance of those Modifications. If any
                Modification to this Privacy Policy is not acceptable to you,
                you should cease accessing, browsing and otherwise using the
                Services.
              </li>
            </StyledOL>
          </li>
          <li>
            <StyledSpan>Dispute Resolution </StyledSpan>
            If you have a complaint about Qlink's privacy practices you should
            contact us. We will take reasonable steps to work with you to
            attempt to resolve your complaint in accordance with the “Terms”
            incorporated with these policies.
          </li>
          <li>
            <StyledSpan>General Data Protection Regulation (GDPR). </StyledSpan>
            The GDPR requires Qlink and Servicers using the Service to provide
            Users with more information about the processing of their Personal
            Data.
            <StyledOL style={{ listStyleType: "lower-alpha" }}>
              <li>
                The GDPR requires us to tell you about the legal ground we're
                relying on to process any Personal Data about you. The legal
                grounds for us processing your Personal Data for the purposes
                set out above will typically be because:
                <StyledOL style={{ listStyleType: "lower-roman" }}>
                  <li>
                    You provided your consent; it is necessary for our
                    contractual relationship;
                  </li>
                  <li>
                    The processing is necessary for us to comply with our legal
                    or regulatory obligations;
                  </li>
                  <li>
                    And/or the processing is in our legitimate interest as a
                    service. 
                  </li>
                </StyledOL>
              </li>
              <li>
                <StyledSpan>Data Controller. </StyledSpan>
                Data protection laws in the EU differentiate between the "data
                controller" and "data processor" of Personal Data. If you signed
                up for the Service on your own, Qlink is the data controller for
                the processing of your Personal Data. You can find our contact
                information, and the contact information of our EU-based
                representative, in the "Contact Us" section below.
              </li>
              <li>
                <StyledSpan>Data Processor </StyledSpan>
                If one of Qlink's business customers has granted you access to
                the Service, Qlink is the data processor for the processing of
                your Personal Data. To exercise the rights described below in
                relation to such processing of Personal Data, please contact the
                applicable business customer. Qlink is also the data processor
                for the processing of Email Content Data on your behalf.
              </li>
              <li>
                <StyledSpan>Legal Bases for Processing. </StyledSpan>
                This Privacy Policy (the paragraph "How We Use Personal Data")
                describes the legal bases we rely on for the processing of your
                Personal Data. Please contact us if you have any questions about
                the specific legal basis we are relying on to process your
                Personal Data.
              </li>
              <li>
                As used in this Privacy Policy, "legitimate interests" means our
                interests in conducting our business and developing a business
                relationship with you. This Privacy Policy describes when we
                process your Personal Data for our legitimate interests, what
                these interests are and your rights. We will not use your
                Personal Data for activities where the impact on you overrides
                our interests, unless we have your consent or those activities
                are otherwise required or permitted by law.
              </li>
            </StyledOL>
          </li>
          <li>
            <StyledSpan>Nevada Residents. </StyledSpan>
            Under Nevada law, certain Nevada residents may opt out of the sale
            of “personally identifiable information” for monetary consideration
            to a person for that person to license or sell such information to
            additional persons. “Personally identifiable information” includes
            first and last name, address, email address, phone number, or an
            identifier that allows a specific person to be contacted either
            physically or online.
            <StyledOL style={{ listStyleType: "lower-alpha" }}>
              <li>
                We do not engage in such activity; however, if you are a Nevada
                resident who has purchased or leased goods or services from us,
                you may submit a request to opt out of any potential future
                sales under Nevada law by emailing us. Please note we will take
                reasonable steps to verify your identity and the authenticity of
                the request. Once verified, we will maintain your request in the
                event our practices change.
              </li>
            </StyledOL>
          </li>
          <li>
            <StyledSpan>Rights for California residents. </StyledSpan>
            If you are a California resident, the California Consumer Privacy
            Act (CCPA) may provide you with additional rights regarding the use
            of your personal information. These rights include
            <StyledOL style={{ listStyleType: "lower-alpha" }}>
              <li>
                The right to access the personal information we process about
                you
              </li>
              <li>
                The right to know what personal information is collected about
                you and if any information is sold or disclosed, and to whom
              </li>
              <li>
                The right to request the deletion of your personal information
              </li>
              <li>
                The right to opt-out from the sale of your personal information
              </li>
              <li>
                The right to not be discriminated against for exercising any of
                the rights above
              </li>
              <li>
                You can exercise any applicable rights by emailing [Email] or
                sending a letter to the address below. You may be required to
                submit proof of your identity and/or address, if we are unable
                to confirm your identity or cannot verify that you are a
                California resident. We will try to respond to a verifiable
                consumer request within 45 days of its receipt.
              </li>
            </StyledOL>
          </li>
          <li>
            <StyledSpan>Transfers of Personal Data. </StyledSpan>
            As Qlink is a global company, we may need to transfer your Personal
            Data outside of the country from which it was originally provided.
            This may be intra-group or to third parties that we work with who
            may be located in jurisdictions outside the EEA, Switzerland and the
            UK which have no data protection laws or laws that are less strict
            compared with those in Europe.
          </li>
          <li>
            <StyledSpan>Change of Ownership. </StyledSpan>
            If we are subject to a merger or acquisition with/by another
            company, we may share information with them in accordance with our
            global privacy standards. Should such an event occur, we will
            require that the new combined entity follow this privacy notice with
            respect to your personal information. If we intend to handle your
            personal information for any purposes not covered in this privacy
            notice, you will receive prior notification of the processing of
            your personal information for the new purposes.
          </li>
          <li>
            <StyledSpan>Contact Us. </StyledSpan>
            If you have a question or a complaint about this privacy notice, our
            global privacy standards, or our information handling practices, you
            can reach us at:
          </li>
          <Typography>AC Advisory, LLC. D.B.A Qlink</Typography>
          <Typography>617 Tremont St. #4</Typography>
          <Typography>Boston, MA 02118</Typography>
        </StyledOL>
      </Stack>
    </>
  );
};

export default PrivacyPolicy;
