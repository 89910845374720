import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { ContextProvider } from "./context/Authcontext";
import Admin from "./pages/admin/Admin";
import Home from "./pages/Home/Home";
import PrivacyPolicy from "./pages/Privacy-policy/PrivacyPolicy";
import TermsOfService from "./pages/Terms-of-service/TermsOfService";

function App() {
  return (
    <>
      <ContextProvider value={500}>
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/admin/*" element={<Admin />} />
            <Route path="/privacy-policy/*" element={<PrivacyPolicy />} />
            <Route path="/Terms-of-service/*" element={<TermsOfService />} />
          </Routes>
        </BrowserRouter>
      </ContextProvider>
    </>
  );
}

export default App;
