import { useCallback, useEffect, useMemo, useState } from "react";

function usePaginate(
  apiLimitSkipCallback,
  pageSize = 20,
  { totalMatchesKey = "totalMatchesCount", resultsKey = "results" } = {}
) {
  const [currentPage, setCurrentPage] = useState(0);
  const [results, setResults] = useState([]);
  const [totalMatchesCount, setTotalMatchesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const totalPages = useMemo(
    () => Math.ceil(totalMatchesCount / pageSize),
    [pageSize, totalMatchesCount]
  );
  const next = useCallback(() => setCurrentPage((v) => v + 1), []);
  const previous = useCallback(() => setCurrentPage((v) => v - 1), []);
  const push = useCallback(
    (v) => setResults((previous) => previous.concat(v)),
    []
  );
  const remove = useCallback(
    (predicate) =>
      setResults((results) =>
        results.filter((x, i, arr) => !predicate(x, i, arr))
      ),
    []
  );
  const update = useCallback(
    (predicate, updates) => {
      const index =
        typeof predicate === "function"
          ? results.findIndex(predicate)
          : results.indexOf(predicate);
      if (index !== -1) {
        const newResults = [...results];
        newResults.splice(index, 1, Object.assign({}, results[index], updates));
        setResults(newResults);
      }
    },
    [results]
  );
  useEffect(() => {
    setCurrentPage(0);
  }, [apiLimitSkipCallback]);
  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    const res = apiLimitSkipCallback(pageSize, currentPage * pageSize);
    console.log(res,"from use paginate")
    if (typeof res.then === "function" && typeof res.catch === "function") {
      res
        .then(
          ({ [totalMatchesKey]: totalMatchesCount, [resultsKey]: results }) => {
            setTotalMatchesCount(totalMatchesCount);
            setResults(results);
          }
        )
        .catch(console.error)
        .then(() => setIsLoading(false));
    } else if (
      typeof res[totalMatchesKey] === "number" &&
      Array.isArray(res[resultsKey])
    ) {
      console.log("to set", res[totalMatchesKey],res[resultsKey])
      setTotalMatchesCount(res[totalMatchesKey]);
      setResults(res[resultsKey]);
      setIsLoading(false);
    } else {
      throw {
        error: "Unknown return value of apiLimitSkipCallback",
        value: res,
      };
    }
  }, [
    apiLimitSkipCallback,
    currentPage,
    pageSize,
    resultsKey,
    totalMatchesKey,
  ]);
  return useMemo(
    () => ({
      currentPage,
      totalMatchesCount,
      results,
      next,
      previous,
      totalPages,
      isLoading,
      push,
      remove,
      update,
      setResults,
    }),
    [
      currentPage,
      isLoading,
      next,
      previous,
      push,
      remove,
      results,
      totalMatchesCount,
      totalPages,
      update,
    ]
  );
}

export default usePaginate;
