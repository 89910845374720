import { Close } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import api from "../../../../api/api";
import InteractiveTableRow from "./InteractiveTableRow";
import usePaginate from "../../../../hooks/usePaginate";
import Pagination from "../../../../components/Pagination";
import useDebounce from "../../../../hooks/useDebounce";
const UserSearch = () => {
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query);
  const onQueryChange = useCallback((ev) => {
    setQuery(ev.target.value);
  }, []);
  const clearQuery = () => {};
  const lookupCallback = useCallback(
    (limit, skip) => {
      return api.admin.getAllUsers({
        limit,
        skip,
        q: debouncedQuery.length > 0 ? debouncedQuery : undefined,
      });
    },
    [debouncedQuery]
  );

  const pagination = usePaginate(lookupCallback, 10);

  return (
    <Stack spacing={2}>
      <TextField
        value={query}
        onChange={onQueryChange}
        size="small"
        placeholder="Search by user ID or email or Full Name"
        autoComplete="off"
        InputProps={{
          endAdornment:
            query.length > 0 ? (
              <InputAdornment position="end">
                <IconButton IconButton size="small" onClick={clearQuery}>
                  <Close fontSize="small" />
                </IconButton>
              </InputAdornment>
            ) : undefined,
        }}
      />
      {pagination.isLoading && <>Loading</>}
      {!pagination.isLoading && pagination.results?.length === 0 && (
        <Typography color="error" alignText="center">
          Couldn&apos;t find any users with such id or email
        </Typography>
      )}
      <Box flex={1}>
        {!pagination.isLoading && (
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>State</TableCell>
                {/* TODO: MAP RESULT OF API CALL HERE  */}
              </TableRow>
            </TableHead>
            <TableBody>
              {!pagination.isLoading &&
                pagination.results !== 0 &&
                pagination.results.map((row) => (
                  <InteractiveTableRow key={row.id} user={row} />
                ))}
            </TableBody>
          </Table>
        )}
        {!pagination.isLoading && pagination.results.length !== 0 && (
          <Pagination
            totalPages={pagination.totalPages}
            nextCallback={pagination.next}
            previousCallback={pagination.previous}
            currentPage={pagination.currentPage}
          />
        )}
      </Box>
    </Stack>
  );
};

export default UserSearch;
