import {
  useMediaQuery,
  Button,
  Container,
  Link,
  Stack,
  TextField,
  useTheme,
  Typography,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import api from "../../../api/api";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import GoogleIcon from "@mui/icons-material/Google";

const StyledStack = styled(Stack)`
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  padding: 20px;
  ${"" /* max-height: calc(25vh - 20px); */}
`;
const StyledTextField = styled(TextField)`
  & input {
    background-color: #ffffff;
  }
`;
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
// consonle.log('test')
const Footer = () => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [type, setType] = useState("success");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    const child = window.open(
      "https://qlinkit.io/beta/signup",
      "",
      "width=400,height=640"
    );
    const listener = (ev) => {
      if (ev.data?.source !== "fastlinker/betaSignup") return;
      if (ev.data.user) {
        setOpen(true);
        setMessage("Account created successfully waiting for admin approval");
      }
      console.log("recieved message", ev.data);
      window.removeEventListener("message", listener);
      child.close();
    };
    child.addEventListener("close", () => {
      window.removeEventListener("message", listener);
    });
    window.addEventListener("message", listener);
  }, []);
  return (
    <StyledStack direction="column" spacing={2}>
      <form onSubmit={handleSubmit}>
        <Stack
          spacing={3}
          justifyContent="center"
          sx={{ width: "100%" }}
          direction={isMobile ? "column" : "row"}
        >
          <Button
            target="_blank"
            type="submit"
            variant="contained"
            sx={{
              borderRadius: "0 1.2rem 0 0 ",
              width: isMobile ? "15rem" : "25rem",
              padding: "1rem",
              borderLeft: "5px solid #57AC95",
            }}
            endIcon={<GoogleIcon sx={{ color: "#FFFFFF" }} />}
          >
            Signup for beta with Google Account
          </Button>
        </Stack>
      </form>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Typography>© 2022 Qlink</Typography>
    </StyledStack>
  );
};

export default Footer;
