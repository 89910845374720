import { Container, Link, Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
const StyledStack = styled(Stack)`
  background-color: #ffffff;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <StyledStack direction="row">
      <Logo
        style={{
          padding: "1rem",
          width: "50px",
          maxWidth: "100%",
          height: "100%",
        }}
      />
      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: "space-between",
          paddingRight: "10px",
          alignItems: "center",
        }}
      >
        <Link
          href="mailto:contact@getqlink.io"
          color="black.main"
          sx={{ fontSize: isMobile && "0.7rem", textDecoration: "none" }}
        >
          GET IN TOUCH
        </Link>
        <Link
          href="/privacy-policy"
          color="black.main"
          sx={{ fontSize: isMobile && "0.7rem", textDecoration: "none" }}
        >
          PRIVACY POLICY
        </Link>
        <Link
          href="/terms-of-service"
          color="black.main"
          sx={{ fontSize: isMobile && "0.7rem", textDecoration: "none" }}
        >
          TERMS OF SERVICE
        </Link>
      </Stack>
    </StyledStack>
  );
};

export default Navbar;
