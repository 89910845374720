import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetState } from 'react-use';
import api from '../api/api';
import {saveToken,getToken,removeToken} from '../api/helpers/auth'
export const AuthContext = React.createContext(null);

const initialState = {
  isLoggedIn: false,
  isLoginPending: false,
  loginError: null,
  user: null,
};

export const ContextProvider = (props) => {
    const [state, setState] = useSetState(initialState);
    const setLoginPending = (isLoginPending) => setState({ isLoginPending });
    const setLoginSuccess = (isLoggedIn) => setState({ isLoggedIn });
    const setLoginError = (loginError) => setState({ loginError });
    const setUser = (user) => setState({ user });
  
    const login = (email, password) => {
      setLoginPending(true);
      setLoginSuccess(false);
      setLoginError(null);
  
      api.admin
        .login(email, password)
        .then((res) => {
          console.log(res);
          if (res.status && res.status === 'fail') {
            setLoginError(res.message);
            throw new Error(res);
          } else {
            console.log(res);
            setLoginPending(false);
            setUser(res.admin);
            saveToken(res.token);
            setLoginSuccess(true);
          }
        })
        .catch((err) => {
            console.log(err);
        });

    };
  
    const getMe = () => {
      setLoginPending(true);
      setLoginSuccess(false);
      setLoginError(null);
      console.log('trying to get user by token');
      api.admin
        .getMe()
        .then((res) => {
          console.log(res.status);
          if (!res.status || res.status !== 'fail') {
            setLoginPending(false);
            setUser(res);
            setLoginSuccess(true);
          } else throw new Error(res);
        })
        .catch((err) => {
          setLoginPending(false);
          setLoginError(err);
        });
    };
  
    const logout = () => {
      setLoginPending(true);
      api.users
        .logout()
        .then((res) => {
          if (res.status === 'success') {
            setLoginPending(false);
            setUser(null);
            setLoginSuccess(false);
          } else throw new Error("Can't login");
        })
        .catch((err) => {
          console.log(err);
        });
    };
  
    // const signup = (name, email, password) => {
    //   setLoginPending(true);
    //   setLoginSuccess(false);
    //   setLoginError(null);
  
    //   api.users
    //     .signup(name, email, password)
    //     .then((res) => {
    //       setLoginPending(false);
    //       setUser(res.user);
    //       //TODO: WRITE FUNCTION TO SAVE USER + ACCESS TOKEN TO CHROME STORAGE
    //       saveToken(res.token);
    //       onSignUp();
    //       setLoginSuccess(true);
    //     })
    //     .catch((err) => {
    //       setLoginError(err);
    //     });
    // };
  
    return (
      <AuthContext.Provider
        value={{
          state,
          login,
          logout,
          getMe,
        }}
      >
        {props.children}
      </AuthContext.Provider>
    );
  };
  