import React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useMediaQuery, useTheme } from "@mui/material";

const Title = styled.h2`
  font-size: 3rem;
  font-weight: 600;
`;

const Character = styled(motion.span)`
  display: inline-block;
  margin-right: -0.05em;

  ${
    "" /* background: linear-gradient(92.16deg, #42deb4 61.77%, #d259ff 77.8%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  }
`;
const Word = styled(motion.span)`
  display: inline-block;
  margin-right: 0.2em;
  white-space: nowrap;
`;

const Typing = ({ text = "animated Text", ...rest }) => {
  const ctrls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 1,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      ctrls.start("visible");
    }
    if (!inView) {
      ctrls.start("hidden");
    }
  }, [ctrls, inView]);

  const characterAnimation = {
    hidden: {
      opacity: 0,
      y: `0.25em`,
    },
    visible: {
      opacity: 1,
      y: `0em`,
      transition: {
        duration: 2,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  };
  const wordAnimation = {
    hidden: {},
    visible: {},
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Title
      aria-label={text}
      role="heading"
      {...rest}
      style={{
        fontSize: isMobile && "1rem",
        margin: "1px",
      }}
    >
      {text.split(" ").map((word, index) => {
        return (
          <Word
            ref={ref}
            aria-hidden="true"
            key={index}
            initial="hidden"
            animate={ctrls}
            variants={wordAnimation}
            transition={{
              delayChildren: index * 0.25,
              staggerChildren: 0.05,
            }}
          >
            {word.split("").map((character, index) => {
              return (
                <Character
                  {...rest}
                  aria-hidden="true"
                  key={index}
                  variants={characterAnimation}
                  style={{ fontSize: isMobile && "1rem" }}
                >
                  {character}
                </Character>
              );
            })}
          </Word>
        );
      })}
    </Title>
  );
};
export default Typing;
