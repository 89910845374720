import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import Redirect from "../../components/Redirect";
import { AuthContext } from "../../context/Authcontext";
import Login from "./components/Login";
import Panel from "./components/Panel";

const Admin = () => {
  const { state: ContextState, login, getMe } = useContext(AuthContext);
  const { isLoginPending, isLoggedIn, loginError } = ContextState;

  useEffect(() => {
    getMe();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin-Dashboard</title>
      </Helmet>
      <Routes>
        <Route
          path="/login"
          element={isLoggedIn ? <Redirect to={"/admin"} /> : <Login />}
        />
        <Route path="*" element={!isLoggedIn ? <Login /> : <Panel />} />
      </Routes>
    </>
  );
};

export default Admin;
