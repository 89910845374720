import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

function Redirect({ to }) {
  const nav = useNavigate();
  useEffect(() => {
    nav(to, { replace: true });
  }, [nav, to]);
  return <></>;
}

Redirect.propTypes = {
  to: PropTypes.string,
};

export default Redirect;