import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styled from "styled-components";
import Card from "../../../components/Card";
import { ReactComponent as LinkedInLogo } from "../../../assets/linkedIn.svg";
import { motion } from "framer-motion";
import AndrewChristo from "../../../assets/AndrewChristo.png";
import TypeIt from "typeit-react";
import Cursor from "../../../components/Animations/Cursor";

const StyledSpan = styled("span")`
  background: linear-gradient(92.16deg, #42deb4 61.77%, #d259ff 77.8%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const parentVariant = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 1, staggerChildren: 2 } },
};

const childrenVariant = {
  initial: { opacity: 0, x: 50 },
  animate: {
    opacity: 1,
    x: 0,
  },
};

const Phase2 = ({ setPhase }) => {
  const [finished, setFinished] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [selectCard, setSelectCard] = useState(false);

  const cardRef = useRef();
  const [x, setX] = useState(10);
  const [y, setY] = useState(10);

  const getPositionCard = () => {
    const x = cardRef.current.offsetLeft;
    const y = cardRef.current.offsetTop;
    setX(x);
    setY(y);
  };
  // Get the position of the red box in the beginning
  useEffect(() => {
    let timer;
    if (showCard) {
      timer = setTimeout(() => {
        getPositionCard();
      }, 500);
    }
    return () => clearTimeout(timer);
  }, [showCard]);

  // Re-calculate X and Y of the red box when the window is resized by the user
  useEffect(() => {
    window.addEventListener("resize", getPositionCard);
  }, []);

  return (
    <Stack
      alignItems={"center"}
      component={motion.div}
      initial="initial"
      animate="animate"
      variants={parentVariant}
      onAnimationComplete={() => {
        setTimeout(() => {
          // setFinished(true);
        }, 1000);
      }}
    >
      <Cursor color="#000000" x={x} y={y} />

      <Typography
        component={motion.div}
        variants={childrenVariant}
        variant="h1"
        sx={{
          fontSize: isMobile && "1.5rem",
        }}
      >
        Make quick introductions:
      </Typography>

      {!finished ? (
        <>
          <TypeIt
            options={{
              speed: 200,
              startDelay: 250,
              afterComplete: () => {
                console.log("completed");
                setShowCard(true);
              },
            }}
            getAfterInit={(instance) => {
              // setInstance(instance);
              console.log(instance);
              return instance;
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: isMobile && "1rem",
                display: "inline-block",
              }}
            >
              Meet our founder //An
            </Typography>
          </TypeIt>

          {showCard && (
            <Card
              component={motion.div}
              variants={childrenVariant}
              borderColor={"#0A66C2"}
              animate={[
                childrenVariant.animate,
                showCard && {
                  scale: [1, 1.1, 0.9, 1],
                  transition: { delay: 2 },
                },
              ]}
              onAnimationComplete={() => {
                setTimeout(() => {
                  setFinished(true);
                }, 3000);
              }}
            >
              <Avatar src={AndrewChristo}></Avatar>
              <Typography
                ref={cardRef}
                variant="h3"
                sx={{
                  fontWeight: "700",
                  fontSize: isMobile ? "0.7rem" : "1.2rem",
                }}
              >
                Andrew Christo
              </Typography>
              <Typography
                sx={{ color: "#C4C2D9", fontSize: isMobile && "0.5rem" }}
              >
                1st
              </Typography>
              <Typography
                sx={{ color: "#C4C2D9", fontSize: isMobile && "0.5rem" }}
              >
                Founder, Qlink
              </Typography>
              <div style={{ flexGrow: 1 }}></div>
              <LinkedInLogo />
            </Card>
          )}
        </>
      ) : (
        <Stack direction="row" spacing={1}>
          <Typography
            variant="h1"
            component={motion.div}
            variants={childrenVariant}
            sx={{
              fontSize: isMobile && "0.85rem",
            }}
          >
            {" "}
            Meet our founder
          </Typography>
          <Link
            component={motion.div}
            sx={{
              fontWeight: 700,
              fontSize: isMobile ? "0.85rem" : "3rem",
              textDecorationLine: "underline",
              color: "#2AA5FC",
            }}
            animate={{ opacity: [0, 0.4, 0.8, 1] }}
            onAnimationComplete={() => {
              console.log("phase 3 should start");
              setTimeout(() => {
                setPhase((prev) => (prev + 1) % 3);
              }, 4000);
            }}
          >
            Andrew Christo
          </Link>
        </Stack>
      )}
    </Stack>
  );
};

export default Phase2;
