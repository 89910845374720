import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  IconButton,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styled from "styled-components";
import Card from "../../../components/Card";
import { ReactComponent as RecentlyLinked } from "../../../assets/recentlyLinked.svg";
import Cursor from "../../../components/Animations/Cursor";
import Typing from "../../../components/Animations/Typing";
import { motion } from "framer-motion";
import TypeIt from "typeit-react";
import ReplayIcon from "@mui/icons-material/Replay";

const StyledSpan = styled("span")`
  background-color: #a3deff;
  border-radius: 4px;
  padding: 2px 5px;
`;
const parentVariant = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 1, staggerChildren: 1 } },
};

const childrenVariant = {
  initial: { opacity: 0, x: 50 },
  animate: {
    opacity: 1,
    x: 0,
  },
};

const Phase3 = ({ setPhase }) => {
  const betaRef = useRef();
  const cardRef = useRef();
  const [finished, setFinished] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [finishCardAnimation, setFinishCardAnimation] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [highlightBeta, setHighlightBeta] = useState(false);
  const [selectBeta, setSelectBeta] = useState(false);
  const [showReplayButton, setShowReplayButton] = useState(false);

  const [x, setX] = useState(10);

  const [y, setY] = useState(10);

  // This function calculate X and Y
  const getPosition = () => {
    const x = betaRef.current.offsetLeft;
    const y = betaRef.current.offsetTop;
    setX(x);
    setY(y);
  };
  const getPositionCard = () => {
    const x = cardRef.current.offsetLeft;
    const y = cardRef.current.offsetTop;
    setX(x + 30);
    setY(y + 30);
  };
  const parentVariant = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 1, staggerChildren: 2 } },
  };

  // Get the position of the red box in the beginning
  useEffect(() => {
    let timer;
    if (selectBeta) {
      timer = setTimeout(() => {
        getPosition();
        setShowCard(true);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [selectBeta]);

  useEffect(() => {
    let timer;
    if (showCard) {
      timer = setTimeout(() => {
        getPositionCard();
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [showCard]);

  // Re-calculate X and Y of the red box when the window is resized by the user
  useEffect(() => {
    window.addEventListener("resize", getPosition);
  }, []);

  return (
    <Stack
      alignItems={"center"}
      component={motion.div}
      initial="initial"
      animate="animate"
      variants={parentVariant}
      spacing={2}
    >
      <Cursor color="#000000" x={x} y={y} />
      <Typography
        variant="h1"
        sx={{
          fontSize: isMobile && "1.5rem",
        }}
      >
        Link it once and qlink remembers:
      </Typography>
      <Stack
        direction="row"
        component={motion.div}
        initial="initial"
        animate="animate"
        alignItems={"center"}
        spacing={1}
        justifyContent="center"
        variants={parentVariant}
        onAnimationComplete={() => {
          setTimeout(() => {
            console.log("ends");
            setFinished(true);
          }, 1000);
        }}
      >
        {highlightBeta ? (
          <>
            <Typography
              variant="h1"
              sx={{
                fontSize: isMobile && "0.85rem",
                display: "inline-block",
              }}
            >
              Join our{" "}
              {!finishCardAnimation ? (
                <span
                  style={{ backgroundColor: highlightBeta && "#A3DEFF" }}
                  ref={betaRef}
                >
                  beta
                </span>
              ) : (
                <Link
                  component={motion.div}
                  sx={{
                    fontWeight: 700,
                    fontSize: isMobile ? "0.85rem" : "3rem",
                    textDecorationLine: "underline",
                    display: "inline-block",
                    color: "#2AA5bb",
                    alignSelf: "center",
                  }}
                  animate={{ opacity: [0, 0.4, 0.8, 1] }}
                  onAnimationComplete={() => {
                    console.log("phase 1 should start");
                    setTimeout(() => {
                      setShowReplayButton(true);
                    }, 1000);
                  }}
                >
                  beta
                </Link>
              )}{" "}
              now
            </Typography>
          </>
        ) : (
          <>
            <TypeIt
              options={{
                speed: 200,
                startDelay: 250,
                afterComplete: () => {
                  setHighlightBeta(true);
                },
              }}
              getAfterInit={(instance) => {
                console.log(instance);
                return instance;
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontSize: isMobile && "0.85rem",
                  display: "inline-block",
                }}
              >
                Join our{" "}
                <span style={{ backgroundColor: highlightBeta && "#A3DEFF" }}>
                  beta{" "}
                </span>{" "}
                now
              </Typography>
            </TypeIt>
          </>
        )}

        {highlightBeta && (
          <TypeIt
            options={{
              speed: 200,
              startDelay: 250,
              afterComplete: () => {
                setSelectBeta(true);
              },
            }}
            getAfterInit={(instance) => {
              // setInstance(instance);
              console.log(instance);
              return instance;
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: isMobile && "0.85rem",
                display: "inline-block",
              }}
            >
              for early access.
            </Typography>
          </TypeIt>
        )}
      </Stack>
      {showReplayButton && (
        <Stack>
          Replay Demo again
          <IconButton disableFocusRipple onClick={() => setPhase(0)}>
            <ReplayIcon />
          </IconButton>
        </Stack>
      )}
      {showCard && (
        <>
          <div ref={cardRef}></div>
          <Card
            borderColor={"#42DEB4"}
            component={motion.div}
            animate={{
              scale: [1, 1.2, 0.9, 1],
            }}
            transition={{
              delay: 2.1,
            }}
            onAnimationComplete={() => {
              setTimeout(() => {
                setFinishCardAnimation(true);
                setShowCard(false);
              }, 1000);
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontWeight: "700",
                fontSize: isMobile ? "0.7rem" : "1.2rem",
              }}
            >
              Qlink Beta signup{" "}
            </Typography>
            <Typography sx={{ fontSize: isMobile && "0.5rem" }}>
              Recently Linked to Beta
            </Typography>
            <div style={{ flexGrow: 1 }}></div>
            <RecentlyLinked />
          </Card>
        </>
      )}
    </Stack>
  );
};

export default Phase3;
